import { Injectable } from '@angular/core'
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core'
import { sentry } from '@src/app/core/services/sentry.service'

@Injectable({ providedIn: 'root' })
export class AppMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    sentry.captureMessage('translation missing: ' + params.key)
    return `[${params.key}]`
  }
}
