import { CommonModule, NgComponentOutlet } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ErrorComponent } from '@src/app/components/error/error.component'
import { ModalComponent } from '@src/app/components/modal/modal.component'
import { ModalDirective } from '@src/app/components/modal/modal.directive'
import { ModalContainerComponent } from '@src/app/components/modal/modal-container.component'
import { AlertComponent } from '@src/app/components/modals/alert.component'
import { CancellationReasonsModalComponent } from '@src/app/components/modals/cancel-subscription/cancellation-reasons-modal.component'
import { ConfirmAddressComponent } from '@src/app/components/modals/confirm-address-modal.component'
import { ConfirmCancelSubscriptionModalComponent } from '@src/app/components/modals/confirm-cancel-subscription-modal.component'
import { ConfirmDiscountComponentModule } from '@src/app/components/modals/confirm-discount/confirm-discount.component.module'
import { ConsentInfoModalComponent } from '@src/app/components/modals/consent-info-modal/consent-info-modal.component'
import { ThermComponent } from '@src/app/components/modals/therm/therm.component'
import { SharedModule } from '@src/app/shared/shared.module'
import { ButtonComponentModule } from '../button/button.component.module'
import { FreeThermModalComponent } from './free-therm-modal/free-therm-modal.component'
import { LinkAccountComponent } from './link-account.component'
import { SimpleModalComponent } from './simple-modal.component'

export const modalComponents = [
  ConfirmAddressComponent,
  ErrorComponent,
  AlertComponent,
  ModalContainerComponent,
  ModalComponent,
  ThermComponent,
  ConsentInfoModalComponent,
  ConfirmCancelSubscriptionModalComponent,
  CancellationReasonsModalComponent,
  SimpleModalComponent,
  FreeThermModalComponent,
  LinkAccountComponent,
]

@NgModule({
  declarations: [...modalComponents, ModalDirective],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDiscountComponentModule,
    ButtonComponentModule,
    NgComponentOutlet,
  ],
  exports: [...modalComponents, ModalDirective],
})
export class ModalModule {}
