import { Routes } from '@angular/router'
import { Page } from '@src/app/shared/typings/enum/pages'

export const ab259Routes: Routes = [
  {
    path: Page.equallyEffectiveInfo,
    loadComponent: () =>
      import('./pages/quiz/equally-effective-info/equally-effective-info.page.component').then(
        m => m.EquallyEffectiveInfoPage,
      ),
    data: { page: Page.equallyEffectiveInfo },
  },
  {
    path: Page.nightSleep,
    loadComponent: () =>
      import('./pages/quiz/questions/night-sleep/night-sleep.page.component').then(
        m => m.NightSleepPage,
      ),
    data: { page: Page.nightSleep },
  },
  {
    path: Page.troubleGettingBackToSleep,
    loadComponent: () =>
      import(
        './pages/quiz/questions/trouble-getting-back-to-sleep/trouble-getting-back-to-sleep.page.component'
      ).then(m => m.TroubleGettingBackToSleepPage),
    data: { page: Page.troubleGettingBackToSleep },
  },
  {
    path: Page.sleepConsistency,
    loadComponent: () =>
      import('./pages/quiz/questions/sleep-consistency/sleep-consistency.page.component').then(
        m => m.SleepConsistencyPage,
      ),
    data: { page: Page.sleepConsistency },
  },
  {
    path: Page.spendingDaysInfo,
    loadComponent: () =>
      import('./pages/quiz/spending-days-info/spending-days-info.page.component').then(
        m => m.SpendingDaysInfoPage,
      ),
    data: { page: Page.spendingDaysInfo },
  },
  {
    path: Page.feelingStressed,
    loadComponent: () =>
      import('./pages/quiz/questions/feeling-stressed/feeling-stressed.page.component').then(
        m => m.FeelingStressedPage,
      ),
    data: { page: Page.feelingStressed },
  },
  {
    path: Page.deviceTrait,
    loadComponent: () =>
      import('./pages/quiz/questions/device-trait/device-trait.page.component').then(
        m => m.DeviceTraitPage,
      ),
    data: { page: Page.deviceTrait },
  },
  {
    path: Page.healthGoals,
    loadComponent: () =>
      import('./pages/quiz/questions/health-goals/health-goals.page.component').then(
        m => m.HealthGoalsPage,
      ),
    data: { page: Page.healthGoals },
  },
  {
    path: Page.biggestStressor,
    loadComponent: () =>
      import('./pages/quiz/questions/biggest-stressor/biggest-stressor.page.component').then(
        m => m.BiggestStressorPage,
      ),
    data: { page: Page.biggestStressor },
  },
  {
    path: Page.favoriteWayToUnwind,
    loadComponent: () =>
      import(
        './pages/quiz/questions/favorite-way-to-unwind/favorite-way-to-unwind.page.component'
      ).then(m => m.FavoriteWayToUnwindPage),
    data: { page: Page.favoriteWayToUnwind },
  },
]
