{
  "account-action--PaymentRequired": "Payment Required",
  "account-action--PaymentRequired--body": "We can't process your subscription's payment. Please try to pay again with your current payment method or add a new one.",
  "account-action--PaymentRequired--cta": "Manage payment methods",
  "account-action--SubscriptionExpired": "Subscription expired",
  "account-action--SubscriptionExpired--body": "You don't have an active subscription. Please choose a new subscription if you would like to start using Natural Cycles again.",
  "account-action--SubscriptionExpired--cta": "Subscribe now",
  "account-action--SubscriptionExpiring": "Subscription expiring",
  "account-action--SubscriptionExpiring--body": "Your subscription expires soon. Please choose a new subscription if you would like to continue using Natural Cycles.",
  "account-action--SubscriptionExpiring--cta": "Subscribe now",
  "account-check-help-center": "Check our Help Center",
  "account-have-questions": "Have questions about your account?",
  "account-page-more-info": "For more information on how to manage your account, read [here](https://help.naturalcycles.com/hc/en-us/categories/360000311753-Subscriptions-Billing). For additional questions please contact our customer support team.",
  "account-page-more-info-iap": "Please note: If you have paid for your subscription through App Store using In-App purchase you need to manage your subscriptions from your phone settings.",
  "account-subscription-ends-on": "Ends on {{date}}",
  "add-card-card-cvv": "CVV",
  "add-card-card-date": "Valid until MM/YY",
  "add-card-card-number": "Card Number",
  "additional-billing-info": "c/o or additional billing info",
  "additional-delivery-info": "c/o or additional delivery info",
  "address-label-region-province": "Province",
  "address-page-title": "Address",
  "alert-change-password-incorrect-current-body": "Sorry, that doesn't seem to be the right password. Please try again.",
  "alert-change-password-incorrect-current-title": "Incorrect old password",
  "alert-confirm-cancel-future-subscription-body": "This {{futurePlan}} plan is set to automatically start on <span style=\"color: #72035d\">{{firstBillingDate}}</span>.\n<br/><br/>\nIf you cancel this upcoming subscription please note:\n<br/>\n<ul>\n<li>All app features will be available until the final day of your current subscription.</li>\n<li>Natural Cycles is a digital service and cannot be returned for a refund.</li>\n</ul>\n<br/>\nWould you still like to cancel your upcoming subscription?",
  "alert-confirm-update-future-subscription-body": "You are currently set to use the {{currentPlan}} subscription. If you select the <span style=\"color: #72035d\">{{futurePlan}} </span>plan instead, it will be set to start automatically on <span style=\"color: #72035d\">{{firstBillingDate}}</span>.\n<br /><br />\nWould you like to choose the <span style=\"color: #72035d\">{{futurePlan}}</span> plan instead of the {{currentPlan}} plan?",
  "alert-logout-other-devices-body": "You will be logged out from Natural Cycles on all other devices. You will still be logged in on this device.",
  "alert-logout-other-devices-title": "Are you sure you want to log out from other devices?",
  "alert-reset-password-success-body": "For security purposes you will be logged out from Natural Cycles on all other devices. You will still be logged in on this page.",
  "alert-reset-password-success-title": "You have successfully updated your password",
  "allow-popups": "Please allow your browser to open popups and try again",
  "almost-there-password-reset": "Almost there! Add your recovery key, type your new password and you're good to go.",
  "anonymous-intro-success-copy-confirmation": "✅ Key is copied to clipboard!",
  "app-feature-ovulation": "Ovulation Detection",
  "app-feature-periods": "Period Tracking",
  "app-feature-predictions": "Cycle Predictions",
  "app-feature-tips": "Personalized Tips",
  "app-ratings": "30k ratings in the App Store",
  "apple-watch-claim-link--text": "Buy your Apple Watch to get started on your journey with Natural Cycles.",
  "apple-watch-claim-link--title": "Choose your Apple Watch",
  "apple-watch-delivery-disclaimer": "Please note that in order to measure with Apple you’ll need to own an Apple Watch with temperature sensors.",
  "apple-watch-no-temp-no-problem": "That’s ok! You can get started with the NC° Thermometer for free when you sign up for the annual plan.",
  "auth-already-have-account": "Already have an account?",
  "auth-apple-existing-account-message": "We've found an account that is linked to this email. Please enter your Natural Cycles password so we can link your account with your Apple ID, this would allow you not to lose any data in the app",
  "auth-before-you-start": "Before you start",
  "auth-consent-withdrawal": "You can withdraw your consent to our processing of your personal data at any time directly in the app or by contacting us at info@naturalcycles.com.\n\nWhen you withdraw your consent, you are unable to use our services in the manner in which they are intended.",
  "auth-continue-with-apple": "Continue with Apple",
  "auth-continue-with-email": "Continue with Email",
  "auth-continue-with-google": "Continue with Google",
  "auth-create-account-txt": "Create an account to get started",
  "auth-create-account-with-email": "Create your account with email",
  "auth-disclaimer-oura1": "Please note:",
  "auth-disclaimer-oura2": "While your goal might be to use the app to prevent a pregnancy, please beware that Natural Cycles is only certified as a birth control in Europe and the US in combination with the use of a basal thermometer. In combination with the Oura ring, Natural Cycles is only available as a <b>beta version</b> and solely intended for use as a fertility tracker.",
  "auth-excluded-password-msg": "The password selected is a commonly used password. Please select a more unique password.",
  "auth-excluded-password-title": "Insecure password",
  "auth-existing-account-header": "You have an account with Natural Cycles",
  "auth-google-error-suppressed-by-user": "Signing in via One Tap is currently disabled. Please try again in 2 hours. Alternatively, try clearing your cache or signing in with a different web browser.",
  "auth-google-existing-account-message": "We've found an account that is linked to this email. Please enter your Natural Cycles password so we can link your account with your Google ID, this would allow you not to lose any data in the app",
  "auth-login": "Log in to your account",
  "auth-login-here": "Log in here",
  "auth-login-to-account-with-email": "Log into your account with email",
  "auth-login-with-apple": "Sign in with Apple",
  "auth-login-with-email": "Sign in with Email",
  "auth-login-with-google": "Sign in with Google",
  "auth-no-account": "Don't have an account?",
  "auth-signin": "Sign in",
  "auth-signup": "Sign up",
  "auth-signup-or-login": "Create account or log in",
  "auth-signup-today": "Sign-up today",
  "auth-terms-age": "I am at least 18 years old.",
  "auth-terms-click-the": "Click the",
  "auth-terms-effectiveness": "I understand that no birth control method is 100% effective, and even with using the app perfectly, I may still have an unintended pregnancy.",
  "auth-terms-emails": "Natural Cycles will send me emails about my purchases and products.",
  "auth-terms-health-data": "I consent to Natural Cycles’ use of sensitive personal data that I add to the app, such as health and intercourse data, to enable and provide the services.",
  "auth-terms-health-data-more": "I consent to that Natural Cycles store my data for 3 years.",
  "auth-terms-icon-above": "icons above for more information.",
  "auth-terms-languages-1": "I understand one of the",
  "auth-terms-languages-2": "supported languages.",
  "auth-terms-legal": "Natural Cycles’ [terms of use](terms-of-use), [privacy policy](privacy-policy) and [user manual](user-manual).",
  "auth-terms-medical": "If pregnancy presents a significant risk to you or the fetus, talk to your doctor before using Natural Cycles.",
  "auth-terms-red-days": "I understand that on red days, I must either abstain or use protection, such as condoms (or another form of barrier protection), to prevent pregnancies.",
  "auth-terms-requirements": "Before you start, please read and accept the following:",
  "auth-terms-stis": "I understand that Natural Cycles does not protect against Sexually Transmitted Infections (STIs).",
  "auth-warn-password": "Password must be at least {{minPwLength}} characters long",
  "auth-welcome-title": "Welcome 🎉",
  "back-to-login": "Back to login",
  "backlit-and-adjustable-beeping": "Backlit and adjustable beeping",
  "billing-address": "Billing address",
  "billing-details": "Billing address",
  "billing-label-country": "Billing country",
  "billing-label-region": "Region",
  "bt-basal-thermometer": "Bluetooth basal thermometer",
  "btn-add": "Add",
  "btn-add-to-cart": "Add to cart",
  "btn-already-using-oura": "I have an Oura Ring",
  "btn-back-to-account": "Back to account",
  "btn-boost": "Boost your body literacy",
  "btn-buy": "Buy",
  "btn-cancel": "Cancel",
  "btn-change-subscription": "Change subscription",
  "btn-confirm": "Confirm",
  "btn-continue": "Continue",
  "btn-continue-quiz": "Continue quiz",
  "btn-credit-card": "Credit Card",
  "btn-delivery-eta": "Show delivery time",
  "btn-goal--PLAN": "Find fertile days",
  "btn-goal--POSTPARTUM": "Recover after birth",
  "btn-goal--PREGNANT": "Monitor pregnancy",
  "btn-goal--PREVENT": "Prevent pregnancy",
  "btn-i-want-to-use-oura": "I need to buy an Oura Ring",
  "btn-ignore": "Ignore",
  "btn-neither-device": "I don’t have either device",
  "btn-no": "No",
  "btn-no-I-dont": "No, I don't",
  "btn-no-remove-therm": "No, remove thermometer",
  "btn-ok": "OK",
  "btn-pay": "Pay {{totalPrice}}",
  "btn-reset": "Reset",
  "btn-retry-payment": "Retry payment",
  "btn-return": "Return",
  "btn-send": "Send",
  "btn-skip": "Skip",
  "btn-start-my-journey": "Start my journey",
  "btn-start-over": "Start over",
  "btn-unluck-healthier": "Unlock a healthier you",
  "btn-yes-keep-therm": "Yes, keep thermometer",
  "btn-yesplease": "Yes, please",
  "cancel-active-subscription-message": "- 🚨 If you decide to renew your subscription manually, you might not be eligible for the same exclusive starting price.\n- All app features will be available until the final day of your current subscription.\n- Natural Cycles is a digital service and cannot be returned for a refund.\n\nDo you want to cancel your subscription?",
  "cancel-active-subscription-title": "Cancel subscription",
  "cancel-feedback-nonpregnant": "It didn't help me to get pregnant",
  "cancel-feedback-other": "Other reasons",
  "cancel-feedback-plan": "I got pregnant and don't need the app anymore",
  "cancel-feedback-prevent": "I experienced an unplanned pregnancy",
  "cancel-feedback-prevent-2": "I no longer need Natural Cycles",
  "cancel-feedback-prevent-3": "I am switching to hormonal birth control",
  "cancel-feedback-reason-1": "I'm trying to save money",
  "cancel-feedback-reason-2": "It doesn't work for me",
  "cancel-feedback-reason-3": "It took too much effort",
  "cancel-subscription-reason--1": "I'm trying to save money",
  "cancel-subscription-reason--2": "It doesn't work for me",
  "cancel-subscription-reason--3": "It took too much effort",
  "cancel-subscription-reason--4": "Other reasons",
  "cancel-subscription-reason--5": "I experienced an unplanned pregnancy",
  "cancel-subscription-reason--6": "I no longer need Natural Cycles",
  "cancel-subscription-reason--7": "I am switching to hormonal birth control",
  "cancel-subscription-reason--8": "I got pregnant and don't need the app anymore",
  "cant-deliver-email": "Oops, we are having trouble reaching this email address. Please double check and confirm it to continue.",
  "cart-AMAZON_GIFT_CARD": "$10 Amazon gift card",
  "cart-APPLE_WATCH": "Apple Watch",
  "cart-BT_THERMOMETER": "NC° Thermometer",
  "cart-CHRISTMAS_LEAFLET_EU": "RE-SET",
  "cart-CHRISTMAS_LEAFLET_US": "RE-SET",
  "cart-CORA_PERIOD_STARTER_KIT": "Cora Period Starter Kit",
  "cart-credit": "Remaining costs adjusted",
  "cart-description-included-for-free": "Included for free",
  "cart-DUREX_CONDOMS_CHE": "Durex condoms",
  "cart-DUREX_CONDOMS_EXP": "Durex condoms",
  "cart-ELLEN_MINI_14": "Ellen tampons",
  "cart-ELLEN_NORMAL_12": "Ellen tampons",
  "cart-exclusiveOfferForOura": "Exclusive offer for Oura members:",
  "cart-FOURMONTH": "4-Month Subscription",
  "cart-GIVZ_DONATION": "$10 to Donate",
  "cart-HALFYEAR": "6-Month Subscription",
  "cart-LELO_CONDOMS_3PK": "LELO condoms",
  "cart-MONTH": "NC° Monthly Subscription",
  "cart-MONTH_ACTIVATION_CARD": "One month access",
  "cart-OURA_MONTH": "First Month Oura Membership",
  "cart-OURA_RING": "Oura Ring",
  "cart-OURA_RING_DISCOUNT": "Oura Ring {{amount}} discount + first month of membership included",
  "cart-OURA_SHIPPING": "Oura Ring shipping",
  "cart-OVUL_TEST": "Ovulation tests 15pcs",
  "cart-OVUL_TEST_SE": "Ovulation tests 15pcs",
  "cart-OVUL_TEST_UK": "Ovulation tests 15pcs",
  "cart-OVUL_TEST_US": "Ovulation tests 15pcs",
  "cart-PREG_TEST": "Pregnancy test 10pcs",
  "cart-PREG_TEST_SE": "Pregnancy test 10pcs",
  "cart-PREG_TEST_UK": "Pregnancy test 10pcs",
  "cart-PREG_TEST_US": "Pregnancy test 10pcs",
  "cart-proration": "Early upgrade discount",
  "cart-QUARTER": "3-Month Subscription",
  "cart-ROLLING_TRIAL": "4 Week Free Trial",
  "cart-ROLLING_TRIAL_7DAYS": "7 Day Free Trial",
  "cart-ROLLING_TRIAL_ACTIVATION_CARD": "4 Week Free Trial",
  "cart-shipping": "Shipping to {{city}} {{region}} {{zip}}\n\n{{address1}}\n\n{{address2}}",
  "cart-shipping-first": "Shipping to {{name}}",
  "cart-shipping-second": "{{address2}}\n\n{{address1}}, {{zip}} {{region}} {{city}}",
  "cart-SUSTAIN_CONDOMS_10": "Sustain Natural Condoms 10-pack",
  "cart-T3_THERMOMETER": "NC° Thermometer",
  "cart-TEMP": "Temporary subscription",
  "cart-THERMOMETER": "NC° Thermometer",
  "cart-TRIAL": "30 day Trial",
  "cart-TRIAL-three": "3-month app trial",
  "cart-variant-celsius": "°C",
  "cart-variant-fahrenheit": "°F",
  "cart-YEAR": "NC° Annual Subscription",
  "cart-YEAR_ACTIVATION_CARD": "One year access",
  "cart-YOPPIE_TAMPON": "Yoppie tampons",
  "celsius": "°Celsius",
  "changeplan-btn-cancelSubs": "Cancel subscription",
  "choose-language": "Choose language",
  "choose-language-english": "Go ahead and choose your preferred language",
  "choose-thermometer": "Choose your thermometer",
  "classic-basal-therm": "Classic body basal thermometer",
  "code-accept": "Get this for {{totalPrice}}",
  "code-accept-free": "Continue",
  "code-active-subscription": "Your account already has an active subscription.",
  "code-applied": "Your promo code has been applied",
  "code-btn-add": "Have a promo code?",
  "code-btn-submit": "Use this code",
  "code-input-placeholder": "Enter your subscription code",
  "code-invalid": "Sorry, {{code}} is not valid",
  "code-referral-title": "{{referralName}} invited you to join Natural Cycles",
  "code-title": "Promotional code",
  "code-title-enter": "Enter your Natural Cycles code",
  "code-txt-freeThermometer": "Thermometer included",
  "code-txt-notes": "If you have bought our Planera Graviditet product the first two letters in the code should be “pl” as in “planera”",
  "confirm-discount-footer": "Would you like to switch the items in your cart?",
  "confirm-discount-text": "You can still <b>use the code with this offer:</b>",
  "confirm-discount-title": "Sorry, promo {{code}} isn’t valid with this selection",
  "consent-marketing-communication": "I want to receive emails about women's health and Natural Cycles.",
  "consent-marketing-communication-info": "We use Identity, Contact, Device and Marketing Data of our users of the App to send you newsletters and other marketing communications e.g. push notifications regarding Natural Cycles and our Services, including campaigns and offers.\n\nWe also process Transaction, Account, Contact and Usage data to conduct internal usage analysis for the purpose of creating and sending relevant messages about our products. Some Marketing Data is collected by using cookies. Please see our <a href=\"https://www.naturalcycles.com/other/legal#cookies\" target=\"_blank\">Cookie Policy</a> for further details.",
  "consent-marketing-communication-title": "Marketing Communication",
  "consent-marketing-info-title": "Info on our social media partners",
  "consent-marketing-social": "I agree to share my pseudonymized email to Social Media Partners to improve the marketing experience.",
  "consent-marketing-social-info": "We use tools that help us identify and reach out to existing and new customers, by matching cookies, device identifiers and hashed (a pseudonymization technique) email addresses of people who have been using our Services with people on social media platforms to create so called “Custom Audiences” (this enables us to send targeted ads to people who have been using our Services), and “Lookalike Audiences” (this enables us to send targeted ads to people who have similar traits to our Custom Audience).\n\nThe social media platforms will not share the hashed email address with third parties or other advertisers and will delete it promptly after the match process is complete. Please note that we do not share any Sensitive Data or group users based on sensitive data for the purpose of Custom and Lookalike Audiences.",
  "consent-marketing-social-title": "Social media marketing - custom audiences, lookalike audiences and advertising",
  "consent-store-data": "I consent to that Natural Cycles store my data for 3 years.",
  "country-AD": "Andorra",
  "country-AE": "United Arab Emirates",
  "country-AF": "Afghanistan",
  "country-AG": "Antigua & Barbuda",
  "country-AI": "Anguilla",
  "country-AL": "Albania",
  "country-AM": "Armenia",
  "country-AO": "Angola",
  "country-AQ": "Antarctica",
  "country-AR": "Argentina",
  "country-AS": "American Samoa",
  "country-AT": "Austria",
  "country-AU": "Australia",
  "country-AW": "Aruba",
  "country-AX": "Åland Islands",
  "country-AZ": "Azerbaijan",
  "country-BA": "Bosnia & Herzegovina",
  "country-BB": "Barbados",
  "country-BD": "Bangladesh",
  "country-BE": "Belgium",
  "country-BF": "Burkina Faso",
  "country-BG": "Bulgaria",
  "country-BH": "Bahrain",
  "country-BI": "Burundi",
  "country-BJ": "Benin",
  "country-BL": "St. Barthélemy",
  "country-BM": "Bermuda",
  "country-BN": "Brunei",
  "country-BO": "Bolivia",
  "country-BQ": "Caribbean Netherlands",
  "country-BR": "Brazil",
  "country-BS": "Bahamas",
  "country-BT": "Bhutan",
  "country-BV": "Bouvet Island",
  "country-BW": "Botswana",
  "country-BY": "Belarus",
  "country-BZ": "Belize",
  "country-CA": "Canada",
  "country-CC": "Cocos (Keeling) Islands",
  "country-CD": "Congo - Kinshasa",
  "country-CF": "Central African Republic",
  "country-CG": "Congo - Brazzaville",
  "country-CH": "Switzerland",
  "country-CI": "Côte d’Ivoire",
  "country-CK": "Cook Islands",
  "country-CL": "Chile",
  "country-CM": "Cameroon",
  "country-CN": "China",
  "country-CO": "Colombia",
  "country-CR": "Costa Rica",
  "country-CU": "Cuba",
  "country-CV": "Cape Verde",
  "country-CW": "Curaçao",
  "country-CX": "Christmas Island",
  "country-CY": "Cyprus",
  "country-CZ": "Czech Republic",
  "country-DE": "Germany",
  "country-DJ": "Djibouti",
  "country-DK": "Denmark",
  "country-DM": "Dominica",
  "country-DO": "Dominican Republic",
  "country-DZ": "Algeria",
  "country-EC": "Ecuador",
  "country-EE": "Estonia",
  "country-EG": "Egypt",
  "country-EH": "Western Sahara",
  "country-ER": "Eritrea",
  "country-ES": "Spain",
  "country-ET": "Ethiopia",
  "country-FI": "Finland",
  "country-FJ": "Fiji",
  "country-FK": "Falkland Islands",
  "country-FM": "Micronesia",
  "country-FO": "Faroe Islands",
  "country-FR": "France",
  "country-GA": "Gabon",
  "country-GB": "United Kingdom",
  "country-GD": "Grenada",
  "country-GE": "Georgia",
  "country-GF": "French Guiana",
  "country-GG": "Guernsey",
  "country-GH": "Ghana",
  "country-GI": "Gibraltar",
  "country-GL": "Greenland",
  "country-GM": "Gambia",
  "country-GN": "Guinea",
  "country-GP": "Guadeloupe",
  "country-GQ": "Equatorial Guinea",
  "country-GR": "Greece",
  "country-GS": "South Georgia & South Sandwich Islands",
  "country-GT": "Guatemala",
  "country-GU": "Guam",
  "country-GW": "Guinea-Bissau",
  "country-GY": "Guyana",
  "country-HK": "Hong Kong SAR China",
  "country-HM": "Heard Island and McDonald Islands",
  "country-HN": "Honduras",
  "country-HR": "Croatia",
  "country-HT": "Haiti",
  "country-HU": "Hungary",
  "country-ID": "Indonesia",
  "country-IE": "Ireland",
  "country-IL": "Israel",
  "country-IM": "Isle of Man",
  "country-IN": "India",
  "country-IO": "British Indian Ocean Territory",
  "country-IQ": "Iraq",
  "country-IR": "Iran",
  "country-IS": "Iceland",
  "country-IT": "Italy",
  "country-JE": "Jersey",
  "country-JM": "Jamaica",
  "country-JO": "Jordan",
  "country-JP": "Japan",
  "country-KE": "Kenya",
  "country-KG": "Kyrgyzstan",
  "country-KH": "Cambodia",
  "country-KI": "Kiribati",
  "country-KM": "Comoros",
  "country-KN": "St. Kitts & Nevis",
  "country-KP": "North Korea",
  "country-KR": "South Korea",
  "country-KW": "Kuwait",
  "country-KY": "Cayman Islands",
  "country-KZ": "Kazakhstan",
  "country-LA": "Laos",
  "country-LB": "Lebanon",
  "country-LC": "St. Lucia",
  "country-LI": "Liechtenstein",
  "country-LK": "Sri Lanka",
  "country-LR": "Liberia",
  "country-LS": "Lesotho",
  "country-LT": "Lithuania",
  "country-LU": "Luxembourg",
  "country-LV": "Latvia",
  "country-LY": "Libya",
  "country-MA": "Morocco",
  "country-MC": "Monaco",
  "country-MD": "Moldova",
  "country-ME": "Montenegro",
  "country-MF": "St. Martin",
  "country-MG": "Madagascar",
  "country-MH": "Marshall Islands",
  "country-MK": "Macedonia",
  "country-ML": "Mali",
  "country-MM": "Myanmar (Burma)",
  "country-MN": "Mongolia",
  "country-MO": "Macau SAR China",
  "country-MP": "Northern Mariana Islands",
  "country-MQ": "Martinique",
  "country-MR": "Mauritania",
  "country-MS": "Montserrat",
  "country-MT": "Malta",
  "country-MU": "Mauritius",
  "country-MV": "Maldives",
  "country-MW": "Malawi",
  "country-MX": "Mexico",
  "country-MY": "Malaysia",
  "country-MZ": "Mozambique",
  "country-NA": "Namibia",
  "country-NC": "New Caledonia",
  "country-NE": "Niger",
  "country-NF": "Norfolk Island",
  "country-NG": "Nigeria",
  "country-NI": "Nicaragua",
  "country-NL": "Netherlands",
  "country-NO": "Norway",
  "country-NP": "Nepal",
  "country-NR": "Nauru",
  "country-NU": "Niue",
  "country-NZ": "New Zealand",
  "country-OM": "Oman",
  "country-PA": "Panama",
  "country-PE": "Peru",
  "country-PF": "French Polynesia",
  "country-PG": "Papua New Guinea",
  "country-PH": "Philippines",
  "country-PK": "Pakistan",
  "country-PL": "Poland",
  "country-PM": "St. Pierre & Miquelon",
  "country-PN": "Pitcairn Islands",
  "country-PR": "Puerto Rico",
  "country-PS": "Palestinian Territories",
  "country-PT": "Portugal",
  "country-PW": "Palau",
  "country-PY": "Paraguay",
  "country-QA": "Qatar",
  "country-RE": "Réunion",
  "country-RO": "Romania",
  "country-RS": "Serbia",
  "country-RU": "Russia",
  "country-RW": "Rwanda",
  "country-SA": "Saudi Arabia",
  "country-SB": "Solomon Islands",
  "country-SC": "Seychelles",
  "country-SD": "Sudan",
  "country-SE": "Sweden",
  "country-SG": "Singapore",
  "country-SH": "St. Helena",
  "country-SI": "Slovenia",
  "country-SJ": "Svalbard & Jan Mayen",
  "country-SK": "Slovakia",
  "country-SL": "Sierra Leone",
  "country-SM": "San Marino",
  "country-SN": "Senegal",
  "country-SO": "Somalia",
  "country-SR": "Suriname",
  "country-SS": "South Sudan",
  "country-ST": "São Tomé & Príncipe",
  "country-SV": "El Salvador",
  "country-SX": "Sint Maarten",
  "country-SY": "Syria",
  "country-SZ": "Swaziland",
  "country-TC": "Turks & Caicos Islands",
  "country-TD": "Chad",
  "country-TF": "French Southern Territories",
  "country-TG": "Togo",
  "country-TH": "Thailand",
  "country-TJ": "Tajikistan",
  "country-TK": "Tokelau",
  "country-TL": "Timor-Leste",
  "country-TM": "Turkmenistan",
  "country-TN": "Tunisia",
  "country-TO": "Tonga",
  "country-TR": "Turkey",
  "country-TT": "Trinidad & Tobago",
  "country-TV": "Tuvalu",
  "country-TW": "Taiwan",
  "country-TZ": "Tanzania",
  "country-UA": "Ukraine",
  "country-UG": "Uganda",
  "country-UM": "U.S. Outlying Islands",
  "country-US": "United States",
  "country-UY": "Uruguay",
  "country-UZ": "Uzbekistan",
  "country-VA": "Vatican City",
  "country-VC": "St. Vincent & Grenadines",
  "country-VE": "Venezuela",
  "country-VG": "British Virgin Islands",
  "country-VI": "U.S. Virgin Islands",
  "country-VN": "Vietnam",
  "country-VU": "Vanuatu",
  "country-WF": "Wallis & Futuna",
  "country-WS": "Samoa",
  "country-XK": "Kosovo",
  "country-YE": "Yemen",
  "country-YT": "Mayotte",
  "country-ZA": "South Africa",
  "country-ZM": "Zambia",
  "country-ZW": "Zimbabwe",
  "default-description": "Natural Cycles is the first method of digital birth control available in the US and Europe. Get started with our Annual subscription today, thermometer included.",
  "default-metatitle": "Natural Cycles | Digital Birth Control",
  "delivery-btn-next": "Next",
  "delivery-btn-shipIt": "Ship it",
  "delivery-by": "by {{date}}",
  "delivery-days": "{{0}}-{{1}} days",
  "delivery-dialog-title": "Once payment is complete, we will ship your thermometer to this address:",
  "delivery-disclaimer": "Due to the coronavirus shipping might take longer than usual.",
  "delivery-estimate-txt": "Estimated delivery time",
  "delivery-hdr-title": "Shipping address",
  "delivery-label-address1": "Street name and number",
  "delivery-label-address2": "c/o, name on the door, apartment, company or building",
  "delivery-label-city": "City, village or locality",
  "delivery-label-country": "Shipping country",
  "delivery-label-country-all": "All countries",
  "delivery-label-country-popular": "Popular countries",
  "delivery-label-cpfNumber": "CPF",
  "delivery-label-free": "Free shipping",
  "delivery-label-name": "Recipient's full name",
  "delivery-label-phone": "Contact number",
  "delivery-label-region": "State",
  "delivery-label-thermometer": "Thermometer temperature units",
  "delivery-label-zip": "ZIP code",
  "delivery-placeholder-address2": "c/o",
  "delivery-placeholder-name": "e.g Jane Doe",
  "delivery-therm-arrive-by": "Your basal thermometer will arrive by <b>{{deliveryDate}}</b>.",
  "disclaimer-effectiveness-in-typical-use": "Natural Cycles is 93% effective with typical use and 98% effective with perfect use",
  "discount-banner-apply-below": "Press apply below to use your code",
  "discount-banner-default-text": "We saved an offer for you {{code}}",
  "email-confirm-msg": "Please check your inbox and confirm your email address.",
  "email-confirm-title": "Confirmation email sent",
  "email-validate-already-in-use": "The email you have entered is already in use with an existing Natural Cycles account",
  "email-validate-another-mail": "Use another email",
  "email-validate-forgot": "I forgot my password",
  "email-validate-interrupt": "Sorry to interrupt",
  "email-validate-login": "Log in with this email",
  "endorsement-bloomberg": "“A powerful alternative”",
  "endorsement-healthline": "“Best app of 2019”",
  "endorsement-the-new-yorker": "“Natural Cycles ticks all the boxes”",
  "endorsements-title": "Featured In",
  "enter-billing-details": "Enter billing address",
  "enter-payment-details": "Enter payment details",
  "enter-shipping-details": "Enter shipping details",
  "error-cannot-delete-active-payment-method": "Oops! It looks like you're trying to remove an active payment method.",
  "exclusive-offer": "Exclusive Offer",
  "exit-quiz": "Exit quiz",
  "fahrenheit": "°Fahrenheit",
  "form-label-code": "Code",
  "form-label-email": "Email",
  "form-label-name1": "First name",
  "form-label-name2": "Last name",
  "form-label-password": "Password",
  "form-label-password-confirm": "Confirm password",
  "get-pregnant-faster": "Get pregnant faster",
  "ifu-disclaimer": "No method of birth control is 100% effective. Even with using the app perfectly, you can still have an unintended pregnancy.",
  "incorrect-email-txt": "Sorry, we couldn't find that email and/or password.",
  "info-barrier-methods-body": "Barrier methods, such as condoms, are methods of birth control that block sperm from entering the uterus.<br /><br />If you are using Natural Cycles as birth control, we recommend you use a barrier method on red (fertile) days.",
  "info-barrier-stis-title": "Sexually transmitted infections (STIs)",
  "info-box-aw-modal": "# Is my Apple Watch compatible? \nYour Apple Watch must have temperature tracking functionality.\n\nDevices currently compatible are:\n- Apple Watch Series 8\n- Apple Watch Ultra",
  "info-contraceptive-typical-pearl": "Typical Use Pearl Index",
  "info-effectiveness-body": "Natural Cycles is 93% effective with typical use, which means that 7 women out of 100 get pregnant during 1 year of use. Using the app perfectly, i.e. if you never have unprotected intercourse on red days, Natural Cycles is 98% effective, which means that 2 women out of 100 get pregnant during 1 year of use.",
  "info-effectiveness-title": "How effective is Natural Cycles?",
  "info-goal--PLAN": "## NC° Plan Pregnancy\n\nChoose ‘NC° Plan Pregnancy’ if you want to get pregnant. You’ll be able to track your unique cycle and get to know your most fertile days, so you can time sex to maximize the chances of conception. \n\nHow fertile you are is shown in a scale of colors in the today view - so you know when’s the best time to try to get pregnant.",
  "info-goal--POSTPARTUM": "## NC° Postpartum\n\nChoose NC° Postpartum if you recently gave birth and would like to get a support during your recovery process.\n\nNC° Postpartum guides users through the physical and mental recovery of childbirth with accessible resources tailored to help promote their overall health and well-being during a crucial transitional period.\n\nTaking your temperature is not required, but measuring is encouraged as it helps our algorithm detect when your fertility returns. Until the NC° app detects your ovulation, it will give you Red Days.",
  "info-goal--PREGNANT": "## NC° Follow Pregnancy\n\nChoose ‘NC° Follow Pregnancy’ if you are already pregnant and would like to monitor your pregnancy.\n\nNatural Cycles will guide you through your pregnancy by providing useful information about the changes happening in your body and the development of your unborn baby.",
  "info-goal--PREVENT": "## NC° Birth Control\n\nChoose ‘NC° Birth Control’ if you want to use Natural Cycles to prevent pregnancy. On red days, you are likely to be fertile, and are at risk of becoming pregnant. On these days you should use protection (such as condoms) or abstain from sex. On green days you can have unprotected sex as you are not fertile.\n\nRed and green days are shown in the today view in the app with red or green colored circles.",
  "info-health-data-analyse": "To calculate your fertility status our algorithm will analyze the following:\n\n* Temperature\n* Period\n* Spotting (bleeding that is not a menstrual period)\n* Ovulation test results",
  "info-health-data-body": "The Natural Cycles app will inform you when and if you are fertile based on the data you add to it. If you use the app to track a pregnancy, the app will provide you with information about the pregnancy.\n\nIt follows from the nature of our services that we must process such data that you add to the services to enable and provide them. Below we have listed some examples of sensitive data that we need for this purpose. For more detailed information on our processing of your personal data, please see our privacy policy.\n\n<h3>To calculate your fertility status</h3>\n\nOur algorithm will calculate your fertility status based on an analysis of the health data that you provide, such as temperature, menstruation and ovulation test results.\n\n<h3>To provide pregnancy information</h3>\n\nIf you are tracking a pregnancy, our app will provide information about the pregnancy based on the health data that you add, such as pregnancy test results.\n\n<h3>To determine the App’s Pearl Index</h3>\n\nIntercourse data is collected and displayed for our users’ convenience, but it is also an important piece of information when determining our overall Pearl Index rate (a calculation used to estimate the effectiveness of birth control).",
  "info-health-data-pregtest-body": "Natural Cycles will use pregnancy test results to analyze your cycle and calculate your pregnancy status.",
  "info-health-data-pregtest-title": "Pregnancy test data",
  "info-health-data-sex-body": "When you log sex, Natural Cycles will save it to be able to display it in the app.",
  "info-health-data-sex-title": "Intercourse data",
  "info-health-data-title": "How we use your sensitive personal data",
  "info-languages-body": "The Natural Cycles app supports the following languages:",
  "info-languages-title": "Supported languages",
  "info-popup-text--APPLE_WATCH": "# Is my Apple Watch compatible?\n\nPlease note: In order to measure with Apple Watch your watch must have a temperature sensor (Apple Watch Series 8 and higher).",
  "info-red-days-body": "On red days – indicated by a red outlined status circle – you are very likely to be fertile and at risk of becoming pregnant.\n<br /><br />\nUse protection (such as condoms or another form of barrier protection) or abstain in order to prevent a pregnancy. Red days are indicated by a red outlined circle.",
  "info-red-days-title": "Red days",
  "info-stis-body": "Sexually transmitted infections (STIs) are diseases that are spread by having sex with someone who has an STI.<br /><br />Natural Cycles does not protect against sexually transmitted infections. Use a condom to protect against STIs.",
  "info-store-data-body": "If you terminate your account with us, we will keep your User Data for an additional 3 years. The reason is to give you the possibility to come back at a different stage in your life and pick up on the data you had then. You always have the right to request your data to be deleted earlier. When we no longer have a reason to keep your Personal Data, if you withdraw your consent or if you successfully request that we erase it, we have processes in place for anonymizing your Personal Data.",
  "info-store-data-title": "How long we keep your personal data",
  "info-table-condom": "Condom (male)",
  "info-table-contraceptive": "Birth Control Method",
  "info-table-IUD": "IUD",
  "info-table-pill": "Birth Control Pill",
  "info-typical-use-body": "The Typical Use Pearl Index of a birth control method estimates how many women (out of 100) will become pregnant during one year.",
  "info-typical-use-title": "Typical Use Pearl Index",
  "info-welcome-3-goal-title": "Choose your goal",
  "intro-btn-forgotPassword": "Forgot your password?",
  "intro-btn-get-started": "Get Started",
  "intro-btn-login": "Log in",
  "intro-btn-noAccount": "I don't have an account",
  "invalid-address-title": "Verify address",
  "invalid-address-txt": "Please double check your address, pay close attention to the zip code and recipient's name, and confirm that it is possible to deliver our package to:",
  "invalid-recovery-key": "This key is invalid. Please make sure you have the correct recovery key in order to reset your password.",
  "is-it-for-me-CTA-description": "Discover everything you need to know about the intelligent birth control app.",
  "is-it-for-me-CTA-title": "Want to learn more?",
  "language-da": "Dansk",
  "language-de": "Deutsch",
  "language-en": "English",
  "language-es": "Español",
  "language-fi": "Suomi",
  "language-no": "Norsk",
  "language-pt": "Português",
  "language-sv": "Svenska",
  "login-not-ready-modal-text": "We are sorry, unfortunately you cannot manage your account on the web yet. Please log in to the app to manage your account.",
  "login-page-text": "Login with your Natural Cycles credentials",
  "login-page-title": "Manage your account",
  "login-testmonial-more": "Read story",
  "login-testmonial-name": "<b>Sophia<br /></b>Cycler since April 2021",
  "login-testmonial-story": "I can’t wait to continue my journey with Natural Cycles as birth control and possibly in the far future to plan pregnancy. That’s one of the many great things about it, it can support someone in all stages of their life.",
  "manage-subscription": "Manage subscription",
  "mandatory-field": "mandatory field",
  "measure-step-1-text": "When you wake up, use the thermometer to take your temperature.",
  "measure-step-1-text-android": "Depending on your device, you can either measure overnight or in the morning.",
  "measure-step-1-text-ios": "Depending on your device, you can either measure overnight or in the morning.",
  "measure-step-1-text-web": "When you wake up, measure your temperature using your basal thermometer and add the reading into the NC° app.",
  "measure-step-1-title": "Measure",
  "measure-step-1-title-web": "Measure your temperature and add data",
  "measure-step-1-title-with-aw": "Measure your temperature",
  "measure-step-2-bt-text": "Sync your temperature with the app and let Natural Cycles analyze your unique cycle and identify your fertility status.",
  "measure-step-2-bt-title": "Sync data",
  "measure-step-2-text": "Enter the reading and the app will analyze your cycle and find your fertility status.",
  "measure-step-2-text-web": "Our algorithm uses temperature, period, and cycle data to quickly see if today is green (not fertile) or red (use protection).",
  "measure-step-2-text-with-aw": "The algorithm behind the app analyzes your body temperature",
  "measure-step-2-title": "Add data",
  "measure-step-2-title-web": "Wait for the algorithm to calculate your fertility",
  "measure-step-2-title-with-aw": "Open the NC° app",
  "measure-step-3-text": "Find out if today is a red or a green day and whether or not you are fertile.",
  "measure-step-3-text-web": "Your daily fertility status can be used to plan or prevent pregnancy. Plus, you’ll receive tailored insights into your own unique cycle.",
  "measure-step-3-text-with-aw": "At a glance, know if today is a Green Day (not fertile) or a Red Day (use protection).",
  "measure-step-3-title": "Learn your fertility",
  "measure-step-3-title-web": "Know your fertility and take control of your cycle",
  "measure-step-3-title-with-aw": "Confirm your fertility status",
  "measure-steps-subtitle": "We’ll take care of the rest",
  "measure-steps-title": "Effectively prevent or plan a pregnancy in 3 quick steps",
  "measure-steps-title-web": "How to use Natural Cycles",
  "measure-title-with-aw": "The only FDA Cleared birth control app",
  "measuring-device-which-device--APPLE_WATCH": "#### Apple Watch\n\nCyclers love being able to go from day to night with the same device and not having to remember to measure in the morning.",
  "measuring-device-which-device--BT_THERMOMETER": "#### NC° Thermometer\n\nCyclers who don’t like wearing devices while they sleep love the routine of measuring when they wake up.",
  "measuring-device-which-device--OURA_RING_DISCOUNT": "#### Oura Ring\n\nCyclers who like tracking their sleep love waking up to their fertility status alongside their sleep insights without having to manually measure.",
  "measuring-device-which-device--T3_THERMOMETER": "#### NC° Thermometer\n\nCyclers who don’t like wearing devices while they sleep love the routine of measuring when they wake up.",
  "measuring-device-which-device--THERMOMETER": "#### NC° Thermometer\n\nCyclers who don’t like wearing devices while they sleep love the routine of measuring when they wake up.",
  "measuring-device-which-device-title": "Can’t decide?",
  "measuring-hw-description--APPLE_WATCH": "Wear it overnight",
  "measuring-hw-description--OURA_RING_DISCOUNT": "Wear it overnight",
  "measuring-hw-description--THERMOMETER": "Measure in the morning",
  "measuring-hw-name--APPLE_WATCH": "Watch Series 8+",
  "measuring-hw-name--BT_THERMOMETER": "NC° Thermometer",
  "measuring-hw-name--OURA_RING_DISCOUNT": "Oura Ring",
  "measuring-hw-name--T3_THERMOMETER": "NC° Thermometer",
  "measuring-hw-name--THERMOMETER": "NC° Thermometer",
  "measuring-hw-priceText--APPLE_WATCH": "Starting from",
  "measuring-hw-priceText--OURA_RING_DISCOUNT": "Starting from",
  "measuring-hw-priceText--THERMOMETER": "with annual plan",
  "measuringDevice-page-title": "What device would you like to measure with?",
  "measuringDevice-which-device-is-for-me": "Which device is right for me?",
  "measuringDevice-which-device-is-for-me-text": "#### Measuring with an Oura Ring\n\nWear the Oura Ring to bed and let it measure while you sleep.\n\n#### Measuring with a thermometer\n\nMeasure in the morning as soon as you wake up.",
  "money-back-guarantee": "Money Back Guarantee",
  "month": "month",
  "months": "months",
  "msg-areYouSure": "Are you sure?",
  "msg-error-title": "Oops! Something went wrong",
  "msg-error-txt": "Oh no, an error has occurred. Please try the following: close the app and reopen it again. If the error still remains, check in your app store that you have the latest version of the app installed. If you are still having problems, please contact our support team and include your registered Natural Cycles email as a reference. Thanks!",
  "next-sub-start": "Your new subscription starts on {{nextBillingDate}}",
  "no-delivery-title": "Get basal thermometer",
  "no-delivery-txt": "Sorry, we do not currently deliver to your location. You can purchase an oral basal thermometer from your nearest pharmacy.",
  "no-thermometer": "I don't need a thermometer",
  "oura-claim-link--text": "The offer is now unlocked! Click the link below to access your exclusive discount on your new Oura Ring.",
  "oura-claim-link--title": "Claim your {{amount}} Oura discount",
  "oura-delivery-disclaimer": "Please note that in order to measure with Oura you’ll need to own an Oura Ring and have an active Oura membership.",
  "oura-page-text": "Unlock your exclusive offer now",
  "oura-page-title": "NC° Powered by Oura",
  "oura-popup-btn-needToBuyOura": "I need to buy an Oura Ring",
  "oura-popup-btn-ownsOura": "I have an Oura Ring",
  "oura-popup-title": "Do you have an Oura Ring?",
  "oura-ring-discount": "Oura Ring discount",
  "oura-ring-discount-description": "Link to exclusive offer after payment",
  "oura-ring-price-text": "From {{price}}",
  "owns-device-popup-btn-no--APPLE_WATCH": "No",
  "owns-device-popup-btn-no--OURA_RING_DISCOUNT": "I need to buy an Oura Ring",
  "owns-device-popup-btn-yes--APPLE_WATCH": "Yes",
  "owns-device-popup-btn-yes--OURA_RING_DISCOUNT": "I have an Oura Ring",
  "password-validate-match": "Passwords do not match",
  "payment-apple-txt": "You are about to launch Apple Pay.",
  "payment-apple-txt-continue": "Your payment will be processed at the last step",
  "payment-disclaimer-payment-method": "Depending on the payment method you choose, purchases will either be charged to your iTunes account upon confirmation of purchase, or securely handled by Braintree, a Paypal service.",
  "payment-disclaimer-subscription-braintree": "Subscriptions will auto-renew on {{nextPayment}} and can be changed or canceled anytime by contacting our Support Team, or by visiting our website and choosing 'Manage Account' from the main menu or under 'Customer Support' at the bottom of the page.",
  "payment-disclaimer-subscription-iap": "For in app-purchases subscriptions will automatically renew (unless auto-renewal is cancelled at least 24 hours prior to the end of the current subscription period). Your account will be charged for this renewal within 24 hours prior to the end of the current subscription period. You can manage your subscription and auto-renewal in your Itunes account setting after purchase.",
  "payment-failure-bank-rejection": "Sorry, your bank declined the payment.",
  "payment-failure-bank-rejection-advise": "Oops! We were not able to process your payment.\n\nPlease try another card/payment method or contact your bank to resolve the issue.",
  "payment-failure-insufficient-funds": "Insufficient funds",
  "payment-failure-insufficient-funds-advise": "Uh-oh. It seems as though you have insufficient funds in your account.\n\nPlease try another card/payment method or contact your bank to resolve the issue.",
  "payment-item--APPLEPAY--label": "Card ending with {{label}}",
  "payment-item--CREDITCARD--label": "Card ending with {{label}}",
  "payment-item--IAPAPPLE--label": "Payment via iTunes",
  "payment-item--PAYPAL--label": "{{label}}",
  "payment-item-expiry-date": "Valid until {{date}}",
  "payment-item-preferred": "Preferred",
  "payment-item-status--expired": "Expired",
  "payment-item-status--expiringSoon": "Expires soon",
  "payment-method-add": "Add payment method",
  "payment-method-APPLEPAY": "Apple Pay",
  "payment-method-CREDITCARD": "Credit card",
  "payment-method-IAPAPPLE": "In-app purchase",
  "payment-method-make-preferred": "Make primary payment method",
  "payment-method-PAYPAL": "PayPal",
  "payment-method-remove": "Remove payment method",
  "payment-paypal-credit-txt": "You are about to be directed to the PayPal page, where you will be given the option to pay the whole amount or split the payment using PayPal Credit.",
  "payment-paypal-txt": "You are about to go to the PayPal page.",
  "payment-security-disclaimer": "Payment data is securely stored and processed by&nbsp;Braintree, a&nbsp;Paypal service.",
  "payment-security-disclaimer-stripe": "Payment data is securely stored and processed by&nbsp;Stripe.",
  "payment-shop-disclaimer": "Physical items can be returned in their original condition within 14 days.",
  "payment-subscription-disclaimer": "Subscriptions will auto-renew on {{nextPayment}} at {{nextPrice}}/{{nextRenewalDurationUnitKey}} and can be changed or canceled anytime by contacting our Support Team, or by visiting our website and choosing 'Manage Account' from the main menu or under 'Customer Support' at the bottom of the page.",
  "payment-subscription-disclaimer-refunds": "Please note that Natural Cycles does not offer a right of return/refunds, as it is a digital service and not a physical item.",
  "payment-subscription-disclaimer-renewal": "Subscription will auto-renew on {{nextPayment}} ({{nextPrice}}) and can be changed or cancelled anytime.",
  "payments-no-methods": "You have no added payment methods",
  "per-month": "/mo",
  "pharmacy-upsell-add-payment-method": "Setup your payment method now so it will be easier to renew your subscription after your 6-months expire",
  "pharmacy-upsell-headline": "Upgrade to Annual Subscription now and save 50%!",
  "pharmacy-upsell-price": "{{price}} extra 6 months",
  "plan-save-description": "Save an extra {{discount}}% on our great value {{plan}} plan",
  "plans-page-access-offer": "Access exclusive offer after payment",
  "plans-page-best-value-year": "Best value: {{savingsTag}} a year",
  "plans-page-billed-annually": "Billed annually",
  "plans-page-billed-annually-after-trial": "billed annually after trial ends",
  "plans-page-cta": "Sign up today",
  "plans-page-disclaimer": "You can change or cancel your subscription at any time.",
  "plans-page-less-stress": "Less stress - just one payment",
  "plans-page-most-popular": "Most popular choice",
  "plans-page-title": "Subscription plans",
  "plans-page-title-2": "Select your subscription",
  "plans-page-what-you-get": "What you get",
  "popup-description--APPLE_WATCH": "Natural Cycles is FDA Cleared to use wrist temperatures from Apple Watch 8 or newer",
  "popup-title--APPLE_WATCH": "Does your Apple Watch have temperature sensors?",
  "popup-title--OURA_RING_DISCOUNT": "Do you have an Oura Ring?",
  "product--AMAZON_GIFT_CARD--title": "$10 Amazon Gift Card",
  "product--APPLE_WATCH--description": "Link to exclusive offer after payment",
  "product--APPLE_WATCH--title": "Apple Watch",
  "product--BT_THERMOMETER--description": "Get the Bluetooth version of our classic basal thermometer and measure and sync smoothly with the Natural Cycles app",
  "product--BT_THERMOMETER--description--detailed": "<p>Upgrade your measuring routine with our game-changing Bluetooth thermometer. Enjoy smoother mornings with these great features:</p>\n<p>\n💡 Measure in the dark with a backlight<br>\n🤫 Turn off unwanted beeping<br>\n🌡️ Save up to ten temperatures<br>\n✅ Sync temperatures via Bluetooth<br>\n</p>",
  "product--BT_THERMOMETER--title": "NC° Thermometer Gen2",
  "product--FOURMONTH--title": "4-Month",
  "product--GIVZ_DONATION--title": "$10 to Donate",
  "product--HALFYEAR--title": "6-Month",
  "product--LELO_CONDOMS_3PK--title": "LELO condoms",
  "product--MONTH--text": "{{price}} per month",
  "product--MONTH--title": "Monthly",
  "product--OURA_RING_DISCOUNT--description": "Link to exclusive offer after payment",
  "product--OURA_RING_DISCOUNT--description--detailed": "<p>The world’s first birth control app powered by a wearable. <br>\n<p><b>😴 Get your mornings back </b></p>\n<p>No more beeps, no more measuring as soon as you wake up. Just wear your ring while you sleep and forget about the rest. </p>\n <p><b>🔄 Adapts to your lifestyle</b></p>\n<p>Irregular sleep? The Natural Cycles algorithm will pick up on it and correct your temperature according to your individual patterns.</p>\n <p><b>🤩 New health insights with the Oura App</b></p>\n<p>Get to know your body even better with Sleep, Activity, Readiness, and so much more… </p>\n</p>",
  "product--OURA_RING_DISCOUNT--title": "Oura Ring {{amount}} discount",
  "product--OURA_RING--title": "Oura Ring",
  "product--OVUL_TEST_CLEARBLUE--title": "Clearblue ovulation tests 10pcs",
  "product--OVUL_TEST--description": "Find your fertile days faster with our Ovulation/LH tests. It helps the algorithm narrow down the fertile window and find your ovulation.",
  "product--OVUL_TEST--description--detailed": "<p>Find your fertile days faster with our multipack of 15 Ovulation/LH tests. Users who pair ovulation tests with the Natural Cycles app can give the algorithm a helping hand as it gets to know your fertile window even better, giving you even more green days. (An average of 5% more green days.) This is useful for both planning and preventing pregnancy.</p>\n\n<p><b>How do LH tests work?</b></p>\n\n<p>These test strips look for a rise in the luteinizing hormone (LH) that suggests ovulation is likely to happen. LH peaks around 48 hours before ovulation indicating that ovulation is likely to happen soon - it does not confirm ovulation has happened. The use of LH tests does not impact the effectiveness of the Natural Cycles app, but it gives more precision for plan users and reduces red days for prevent users.</p>",
  "product--OVUL_TEST--title": "Ovulation tests 15pcs",
  "product--PREG_TEST--description": "With these home pregnancy tests you will find out as early as possible if you are pregnant or not.",
  "product--PREG_TEST--description--detailed": "<p>If you’re a plan user in the Natural Cycles app, you’ll be prompted on the best days to take a pregnancy test, otherwise, we recommend testing on the first day of your missed period. These safe and easy to use pregnancy tests are CE Marked in Europe, FDA Cleared in the US and boast over 99.9% accuracy. The app will switch to our free Follow Mode when you add a positive result.</p>\n\n<p><b>How do pregnancy tests work?</b></p>\n\n<p>These pregnancy tests work by measuring hormone levels in urine. Dip the strip into a container holding a urine sample. Lay the test on a flat surface; the result should be ready in 3 minutes. Read the instructions on the packet before use.</p>",
  "product--PREG_TEST--title": "Pregnancy test 10pcs",
  "product--QUARTER--title": "3-Month Subscription",
  "product--ROLLING_TRIAL_7DAYS--title": "7 Day Free Trial",
  "product--ROLLING_TRIAL--description": "Automatic renewal after trial",
  "product--ROLLING_TRIAL--text": "One month free of charge",
  "product--ROLLING_TRIAL--title": "4 week Trial",
  "product--SUBSCRIPTION_PRORATION_DISCOUNT--title": "Early upgrade discount",
  "product--T3_THERMOMETER--description--detailed": "<p>Get your hands on our new Bluetooth thermometer! Right now this exclusive offer is only open to our loyal Cyclers. Order yours while stocks last!</p>\n<p>\n💡 Read data easily with a light-up screen<br>\n🤫 Turn off unwanted beeping<br>\n🌡️ Save up to ten temperatures<br>\n✅ Sync temperatures via Bluetooth\n</p>",
  "product--T3_THERMOMETER--title": "NC° Thermometer Gen3",
  "product--THERMOMETER--description": "Natural Cycles is only effective with an oral basal thermometer (one with 2 decimals).",
  "product--THERMOMETER--description--detailed": "<p>Not your average thermometer, our basal thermometer works for both Fahrenheit and Celsius and measures body temperature to two decimal places (i.e. 98.31° F (36.65°C).) This specificity is needed for detecting the rise and fall in temperature that happen throughout the menstrual cycle. When you’re using the Natural Cycles app, the algorithm will use these subtle temperature changes to predict and confirm your ovulation. This BBT thermometer can also measure body temperature to detect fever or chills. Just like the Natural Cycles app, our basal thermometer is CE marked in Europe.</p>\n\n<p><b>How to use a Basal thermometer with Natural Cycles</b></p>\n\n<p>Measure in the morning as soon as you wake up. Input the temperature reading into the app and the algorithm will tell you today’s fertility status i.e. Green Day (not fertile) and Red Day (fertile, you need to use protection). It doesn’t matter if you can’t measure every single day of your cycle, the algorithm can still get to know you if you can measure most of the time. If you’re a snoozer, don’t worry the basal thermometer stores your last temperature so you can measure and input data later.</p>",
  "product--THERMOMETER--text": "Buy separately for {{price}}\n\nFree shipping worldwide",
  "product--THERMOMETER--title": "NC° Thermometer Gen1",
  "product--TRIAL--title": "30 day Trial",
  "product--YEAR--text": "{{price}} per year ({{monthlyPrice}} per month)",
  "product--YEAR--title": "Annual",
  "product-description-OVUL_TEST": "Find your fertile days faster with our Ovulation/LH tests. It helps the algorithm narrow down the fertile window and find your ovulation.",
  "product-description-PREG_TEST": "With these home pregnancy tests you will find out as early as possible if you are pregnant or not.",
  "product-description-THERMOMETER": "Natural Cycles is only effective with an oral basal thermometer (one with 2 decimals).",
  "product-offer-tag": "new!",
  "product-save-tag": "save {{amount}}",
  "product-title-app--MONTH": "Monthly Plan",
  "product-title-app--YEAR": "Annual Plan",
  "pwReset-msg-txt": "Please check your email, we have sent you a new password.",
  "recaptcha-disclaimer": "This site is protected by reCAPTCHA and the Google <a target='_blank' href='https://policies.google.com/privacy'>Privacy Policy</a> and <a target='_blank' href='https://policies.google.com/terms'>Terms of Service</a> apply.",
  "receipt-popup-header": "Do you need a receipt?",
  "receipt-popup-text": "Please fill in your full name if you would like an itemized receipt for FSA/HSA reimbursement of your purchase.",
  "referral-code-discount": "20% OFF",
  "referral-code-headline": "Cycle with me!",
  "referral-code-text": "Sign up for the world’s first birth control app with my personal offer and save 20% on your Annual Subscription.",
  "reg-btn-go": "Go",
  "reg-btn-sendIt": "Send it",
  "reg-cb-terms": "terms of use",
  "reg-code-free-months": "free months",
  "reg-code-friend-item2": "Thermometer",
  "reg-code-will-give-you": "This code will give you",
  "reg-code-with": "with",
  "reg-code-worth": "worth",
  "reg-download-title": "That's it!",
  "reg-download-txt": "Download the Natural Cycles app, log in with <b>{{email}}</b> and set up your personal experience.",
  "reg-download-txt-evow": "Head back over to the NC° app, login and set up your personal experience.",
  "reg-download-txt-oura": "Download the Natural Cycles app, log in with <b>{{email}}</b> and start your journey with Natural Cycles and ŌURA.",
  "reg-download-txt-postpartum-prerelease": "While you can download the NC° app, if you cannot access NC° Postpartum, please allow 24-48 hours as we await for the Apple App Store to approve and release this new experience!",
  "reg-download-useweb": "(You can download the app at any time)",
  "reg-download-useweb-link": "Continue in browser",
  "reg-install": "Install the mobile app",
  "reg-label-email": "Your email",
  "reg-label-password": "Select password",
  "reg-label-passwordRepeat": "Repeat password",
  "reg-refer-friend-anual": "Annual Plan",
  "reg-reg-terms": "I accept Natural Cycles'",
  "reg-regulatory-appendix": "and I'm over 18 years of age.",
  "reg-regulatory-privacy": "privacy policy",
  "reg-setup": "One-click log in to the app",
  "reg-warn-acceptTerms": "Please accept the terms",
  "reset-password-confirm": "Confirm new password",
  "reset-password-email-sent": "If you already have a Natural Cycles account, you will have received an email with further instructions.",
  "reset-password-expired": "Link has expired",
  "reset-password-keep-safe": "We suggest you use a paper or a password manager.\nIt’s important to keep it safe. You will need it if you ever forget your password.",
  "reset-password-logged-out": "For security purposes you will be logged out from Natural Cycles on all other devices and this is your new recovery key.",
  "reset-password-modal-body": "Enter the email address associated with your Natural Cycles account. We’ll email your password reset instructions.",
  "reset-password-page-text": "Almost there! Type your new password and you're good to go.",
  "reset-password-page-title": "Reset your password",
  "reset-password-resend-btn": "Resend Email",
  "reset-password-step-1": "Step 1: Add recovery key",
  "reset-password-step-1-text": "You received your Recovery Key when enabling Go Anonymous. Please check where you stored it and add it here.",
  "reset-password-step-2": "Step 2: Create new password",
  "reset-password-step-2-text": "Enter your new password twice to confirm it.",
  "reset-password-success": "Password successfully changed!",
  "reset-password-title": "Reset password",
  "same-as-shipping-address": "Use Shipping Address as Billing Address",
  "science-consent-modal-title": "Help further science",
  "secret-page-hero-title": "The world's first birth control app",
  "section-privacy": "Privacy Policy",
  "section-science": "The science",
  "section-terms": "Terms of use",
  "settings-healthData-revoke-body": "Without access to your health data the app won’t function. This means we won’t be able to show your fertility status or to give you updates about your cycle.",
  "settings-science-consent": "Natural Cycles can use my health data for research in women’s reproductive health.",
  "settings-science-consent-more": "(for more information see ch. 4.3 in our Privacy Policy)",
  "settings-science-consent-policy": "Women's health is important to Natural Cycles and we invest in scientific research in sexual and reproductive health in order to advance women’s health. We also conduct research for the purpose of evaluating the effectiveness and suitability of the App for different user groups. Thirdly we use the results of our research to communicate the benefits and limitations of Natural Cycles to healthcare professionals. All our published research is subjected to independent peer review and has ethical approval from the relevant professional bodies.\n\nIf we have your consent, we will use your User Data and other Personal Data that you may provide, in pseudonymized or anonymized form (see the Glossary in the Privacy Policy for more information on pseudonymization and anonymization), for clinical studies, scientific articles and other research purposes as may be disclosed when your Personal Data is collected. However, Personal Data is anonymized and aggregated before any such publications are shared outside of Natural Cycles. We may also contact you with requests to participate in specific research projects run by us or our business partners.\n\nNatural Cycles also contribute to research carried out by selected universities, institutions and other parties by sharing anonymized and minimized data with them. For the avoidance of doubt, we do not share any Personal Data with such external parties.\n\nFinally, we may analyze sensitive data in order to publicly share insights learned from aggregated data with the purpose of increasing the public knowledge and understanding of women's health and/or the menstrual cycle. This kind of publication is always based on aggregated anonymized data and as such doesn’t contain any personal information.",
  "shipping-address": "Shipping address",
  "special-offer": "Special Offer",
  "subscription-cancel-feedback": "We would appreciate your feedback. What is the reason for your cancellation?",
  "subscription-changed": "Subscription successfully updated!",
  "subscription-renewal": "Renewal will start on",
  "subscription-renewal-cancelled": "The automatic renewal of your {{plan}} has been cancelled. Your {{plan}} will last until {{nextBillingDate}}.",
  "subscription-renewal-expired": "Your {{plan}} expired on {{nextBillingDate}}.",
  "subscription-renewal-non-renewing": "Your {{plan}} will end on {{nextBillingDate}}.",
  "subscription-renewal-pending": "Your {{plan}} will start on {{firstBillingDate}}.",
  "subscription-renewal-renewing": "Your {{plan}} will automatically renew on {{nextBillingDate}}.",
  "subscription-star-price": "Free for life",
  "subscription-star-title": "Star Cycler Subscription",
  "subscription-status--ACTIVE": "Active",
  "subscription-status--CANCELED": "Canceled",
  "subscription-status--PENDING": "Upcoming",
  "sync-seamlessly": "Syncs temperature seamlessly",
  "tag-free": "Free",
  "tag-off": "{{amount}} off",
  "tag-recommended": "recommended",
  "take-the-quiz": "Take the quiz",
  "terms-apply-btn": "terms apply",
  "therm-popup-headline": "You need a BBT thermometer to use Natural Cycles",
  "therm-popup-headline2": "Why a BBT thermometer?",
  "therm-popup-headline3": "How does it work?",
  "therm-popup-precision-text": "BBT thermometers take a more specific temperature reading than regular fever thermometers. This is needed to detect the subtle changes that happen during your cycle.",
  "therm-popup-purchase-button": "Buy BBT thermometer",
  "therm-popup-skip-button": "Skip this",
  "therm-popup-step1-headline": "1. Measure",
  "therm-popup-step1-text": "When you wake up in the morning, use the Natural Cycles thermometer to take your temperature.",
  "therm-popup-step2-headline": "2. Add data",
  "therm-popup-step2-text": "Enter your temperature into the app and Natural Cycles will analyze your unique cycle and identify your fertility status.",
  "therm-popup-step3-headline": "3. Learn your fertility",
  "therm-popup-step3-text": "Instantly find out your fertility status for the day and see where you are in your cycle.",
  "therm-popup-subheadline": "🎯 Precision",
  "therm-popup-subheadline2": "💜 Tailored to you",
  "therm-popup-tailored-text": "Natural Cycles is powered by an algorithm that uses your unique BBT data to calculate a fertility status for every single day of your cycle.",
  "therm-popup-text": "To use Natural Cycles you need a basal body thermometer. This measures temperature to two decimals.",
  "therm-txt-ab-body": "Natural Cycles is only effective with an oral basal thermometer (one with 2 decimals).",
  "therm-txt-ab-body-MONTH": "Natural Cycles is only effective with an oral basal thermometer (one with 2 decimals).\n\nAdd one to your order to get the most out of your monthly plan.",
  "therm-txt-ab-body-NONDELIVERABLE-1": "Natural Cycles is only effective with an oral basal thermometer (one with 2 decimals).",
  "therm-txt-ab-body-NONDELIVERABLE-2": "You can buy one at your local pharmacy.",
  "therm-txt-ab-body-QUARTER": "Natural Cycles is only effective with an oral basal thermometer (one with 2 decimals).\\n\\n\nAdd one to your order to get the most out of your quarterly plan.",
  "therm-txt-ab-body-TRIAL": "Natural Cycles is only effective with an oral basal thermometer (one with 2 decimals).\n\nAdd one to your order to get the most out of your trial.",
  "therm-txt-ab-body-YEAR": "Natural Cycles is only effective with an oral basal thermometer (one with 2 decimals).\n\nAdd one to your order to get the most out of your yearly plan.",
  "therm-txt-ab-cta-buy": "Buy thermometer for {{price}}",
  "therm-txt-ab-cta-include": "Yes, include thermometer",
  "therm-txt-ab-cta-no": "I already have a thermometer",
  "therm-txt-ab-title": "Do you need a thermometer?",
  "therm-txt-ab-title-NONDELIVERABLE": "Don’t forget to pick up a thermometer",
  "therm-txt-dont": "Yes, I need to get one",
  "therm-txt-own": "No, I already have one",
  "therm-txt-rec": "For the app to work accurately you will need one. A regular fever thermometer won't do, unfortunately.",
  "therm-txt-skip": "Continue without thermometer",
  "therm-txt-suggest": "Need a thermometer?",
  "therm-txt-title": "Do you need a thermometer that shows two decimals?",
  "title-order-placed": "Thanks for your order!",
  "title-usps": "Let’s find out how the first FDA Cleared birth control app can help you",
  "toggle-password-password": "Show password",
  "toggle-password-text": "Hide password",
  "tutorial-box-safe-data": "How is my identity kept anonymous?",
  "tutorial-box-safe-data-body": "If you choose to Go Anonymous, we can seperate your personal and fertility data in our database. You’re the only one with a unique key that links the two. </br></br> <b> Note! </b> Your data is always protected by NC° Secure, but this extra layer of identity protection makes it impossible for us to know who you are.",
  "txt--bleedingChanges": "What bleeding changes do you experience?",
  "txt--bleedingChanges--content": "This helps us find the best cycle trackers for you",
  "txt--cervicalMucusChanges": "What cervical mucus changes do you experience?",
  "txt--cervicalMucusChanges--content": "This helps us find the best cycle trackers for you",
  "txt--cycleChanges": "What cycle changes do you notice?",
  "txt--cycleChanges--content": "This helps us find the best cycle trackers for you",
  "txt--emotions": "What emotions do you experience?",
  "txt--emotions--content": "This helps us find the best cycle trackers for you",
  "txt--libidoChanges": "What libido changes do you experience?",
  "txt--libidoChanges--content": "This helps us find the best cycle trackers for you",
  "txt--skinChanges": "What skin changes do you experience?",
  "txt--skinChanges--content": "This helps us find the best cycle trackers for you",
  "txt--typeOfPain": "What type of pain do you experience?",
  "txt--typeOfPain--content": "This helps us find the best cycle trackers for you",
  "txt-accept": "Accept",
  "txt-accept-free-therm": "I want a free NC° Thermometer",
  "txt-account": "Account",
  "txt-account-overview": "Account overview",
  "txt-action-required": "Action required",
  "txt-add-therm": "Would you like to add a new thermometer to your order?",
  "txt-address": "Address",
  "txt-all-set": "You are all set!",
  "txt-amount": "Amount",
  "txt-analyzing-cycle": "Analyzing your cycle....",
  "txt-and": "and",
  "txt-anonymized": "Anonymized",
  "txt-anonymized-account": "Anonymized account",
  "txt-answer--almost": "Almost...",
  "txt-answer--correct": "That’s correct!",
  "txt-answer--false": "False",
  "txt-answer--true": "True",
  "txt-apple-login": "Apple Login",
  "txt-apply": "Apply",
  "txt-available-subscription": "Available subscription",
  "txt-AvgCycleLengthOption--lessThanTwentyOne": "Less than 21 days",
  "txt-AvgCycleLengthOption--moreThanThirtyFive": "More than 35 days",
  "txt-AvgCycleLengthOption--notSure": "Not sure",
  "txt-AvgCycleLengthOption--twentyEightToThirtyFive": "28 - 35 days",
  "txt-AvgCycleLengthOption--twentyOneToTwentyEight": "21 - 28 days",
  "txt-barrier-methods": "Barrier methods",
  "txt-basal-thermometer": "Oral basal thermometer",
  "txt-basal-thermometer-included": "Oral basal thermometer included",
  "txt-best-device--APPLE_WATCH": "Apple Watch is the device for you!",
  "txt-best-device--BT_THERMOMETER": "NC° Thermometer is the device for you!",
  "txt-best-device--OURA_RING_DISCOUNT": "Oura Ring is the device for you!",
  "txt-best-device--T3_THERMOMETER": "NC° Thermometer is the device for you!",
  "txt-best-device--THERMOMETER": "NC° Thermometer is the device for you!",
  "txt-best-device-info": "Based on your routine and sleep patterns, it’s the best way for you to measure.",
  "txt-best-option": "This helps us find the best option for you",
  "txt-best-support-you": "This helps us determine how best to support you",
  "txt-best-time-in-cycle": "At what point in your cycle do you feel your best?",
  "txt-BestTimeInCycleOption--afterPeriod": "After my period",
  "txt-BestTimeInCycleOption--beforePeriod": "Before my period",
  "txt-BestTimeInCycleOption--dontKnow": "I don’t know",
  "txt-BestTimeInCycleOption--duringPeriod": "During my period",
  "txt-BestTimeInCycleOption--ratherNotSay": "I’d rather not say",
  "txt-biggest-stressor": "What’s the biggest stressor in your life?",
  "txt-BiggestStressorOption--family": "Family",
  "txt-BiggestStressorOption--finances": "Finances",
  "txt-BiggestStressorOption--future": "Future",
  "txt-BiggestStressorOption--health": "Health",
  "txt-BiggestStressorOption--other": "Other",
  "txt-BiggestStressorOption--work": "Work",
  "txt-billing": "Billing",
  "txt-billing-MONTH": "Billed monthly",
  "txt-billing-ROLLING_TRIAL": "Automatic renewal after trial",
  "txt-billing-ROLLING_TRIAL_7DAYS": "Automatic renewal after trial",
  "txt-billing-YEAR": "Billed yearly",
  "txt-BirthControlMethodBreakpointOption--condom": "Condom",
  "txt-BirthControlMethodBreakpointOption--copperIUD": "Copper IUD",
  "txt-BirthControlMethodBreakpointOption--hormonalIUD": "Hormonal IUD",
  "txt-BirthControlMethodBreakpointOption--implant": "Implant",
  "txt-BirthControlMethodBreakpointOption--none": "None",
  "txt-BirthControlMethodBreakpointOption--nuvaRing": "NuvaRing",
  "txt-BirthControlMethodBreakpointOption--other": "Other",
  "txt-BirthControlMethodBreakpointOption--patch": "Patch",
  "txt-BirthControlMethodBreakpointOption--pill": "Birth control pill",
  "txt-BirthControlMethodBreakpointOption--pullOut": "Pull out method",
  "txt-BirthControlMethodBreakpointOption--shot": "Birth control shot",
  "txt-bleeding-changes": "What bleeding changes do you experience?",
  "txt-BleedingChangesOption--heavyPeriod": "Heavy period",
  "txt-BleedingChangesOption--lightPeriod": "Light period",
  "txt-BleedingChangesOption--mediumPeriod": "Medium period",
  "txt-BleedingChangesOption--spotting": "Spotting",
  "txt-buy-apple-watch--button": "Buy Apple Watch",
  "txt-calculating-effectiveness": "Calculating fertility...",
  "txt-can-not-decide-section": "All devices have undergone clinical evaluations to study the temperature data used with Natural Cycles. These evaluations have been reviewed by the FDA. No matter your choice, all connect to the same NC° app with the same effectiveness\n<br /><br />\nHere’s what we hear from our users:",
  "txt-cancel-current-sub": "Cancel current subscription",
  "txt-cancel-upcoming-sub": "Cancel upcoming subscription",
  "txt-cart-empty": "Your cart is empty",
  "txt-cervical-mucus-changes": "What cervical mucus changes do you experience?",
  "txt-CervicalMucusChangesOption--creamy": "Creamy",
  "txt-CervicalMucusChangesOption--eggWhite": "Egg white",
  "txt-CervicalMucusChangesOption--heavy": "Heavy",
  "txt-CervicalMucusChangesOption--light": "Light",
  "txt-CervicalMucusChangesOption--medium": "Medium",
  "txt-CervicalMucusChangesOption--none": "None",
  "txt-CervicalMucusChangesOption--sticky": "Sticky",
  "txt-CervicalMucusChangesOption--watery": "Watery",
  "txt-checkout": "Checkout",
  "txt-choose-how-to-measure": "Choose how to measure",
  "txt-choose-your-plan": "Choose your plan",
  "txt-churn-therm": "Hey there! It looks like you already have an account with us. The offer of a free thermometer is only available for new users who don’t have one yet. Please choose if you’d like to add the cost of a thermometer to your order.",
  "txt-claim-discount": "Claim discount",
  "txt-ConceiveTimingBreakpointOption--fewMonths": "A few months",
  "txt-ConceiveTimingBreakpointOption--justStarted": "Only just started",
  "txt-ConceiveTimingBreakpointOption--moreThanAYear": "More than a year",
  "txt-ConceiveTimingBreakpointOption--notStarted": "Haven’t started yet",
  "txt-ConceiveTimingBreakpointOption--sixMonths": "About 6 months",
  "txt-conditions": "Do you have any of these conditions?",
  "txt-confirm-email-popup": "Does this look right?",
  "txt-considering-purchase": "Do you plan to purchase either device?",
  "txt-ConsideringPurchaseOption--no": "No",
  "txt-ConsideringPurchaseOption--yesAppleWatch": "Yes, Apple Watch",
  "txt-ConsideringPurchaseOption--yesOuraRing": "Yes, an Oura Ring",
  "txt-contraception": "Birth control",
  "txt-copy-to-clipboard": "Copy to clipboard",
  "txt-create-account": "Create an account",
  "txt-current-bc-method": "What birth control method do you use?",
  "txt-current-password": "Current password",
  "txt-current-plan": "Current plan",
  "txt-current-subscription": "Current subscription",
  "txt-cycle-changes": "What cycle changes do you notice?",
  "txt-cycle-changes-content": "This helps us find the best cycle trackers for you",
  "txt-cycle-insight": "This helps us get insights into your cycle regularity",
  "txt-cycle-lenght": "On average, how long is your cycle?",
  "txt-cycle-lenght-before-hormones": "On average, how long was your cycle before starting hormonal birth control?",
  "txt-cycle-lenght-predictions": "This helps us predict your future cycle patterns",
  "txt-CycleChangesBreakpointOption--bleeding": "Bleeding",
  "txt-CycleChangesBreakpointOption--cervicalMucus": "Cervical mucus",
  "txt-CycleChangesBreakpointOption--emotions": "Emotions",
  "txt-CycleChangesBreakpointOption--pain": "Pain",
  "txt-CycleChangesBreakpointOption--sexAndLibido": "Sex & libido",
  "txt-CycleChangesBreakpointOption--skinChanges": "Skin changes",
  "txt-cyclers-over-10-years": "Cyclers over 10 years",
  "txt-data-protection": "You’re covered by our advanced data protection at all times. Read our <a href=\"{{link}}\" target=\"_blank\">Privacy policy</a> to learn more.",
  "txt-decline": "Decline",
  "txt-device-info--APPLE_WATCH": "Wrist temperature syncs as soon as you wake up",
  "txt-device-info--default": "Take your temperature in your mouth when you wake up",
  "txt-device-info--OURA_RING_DISCOUNT": "Sync temperature trends  when you wake up",
  "txt-device-trait": "What device trait do you value most?",
  "txt-DeviceTraitOption--costConscious": "A cost-conscious option",
  "txt-DeviceTraitOption--notSure": "I’m not sure",
  "txt-DeviceTraitOption--premium": "A premium experience",
  "txt-different-journey": "This journey is different for everyone We’re here to help along the way",
  "txt-discussed-fertility": "Have you discussed your fertility with your doctor?",
  "txt-DiscussedFertilityOption--noButGoingTo": "No, but I am going to",
  "txt-DiscussedFertilityOption--noDontPlanTo": "No, I don’t plan to",
  "txt-DiscussedFertilityOption--yesIHave": "Yes, I have already",
  "txt-do-you-want-to-continue": "Do you want to continue from where you left off?",
  "txt-does-this-look-right": "Does this look right?",
  "txt-dont-know": "I don't know",
  "txt-download": "Download",
  "txt-easy-to-share-cycle-insight": "We’ll make it easy for you to share cycle insights",
  "txt-edit": "Edit",
  "txt-effective-typical-use": "NC° Birth Control is 93% effective with typical use and 98% with perfect use",
  "txt-effectiveness": "effectiveness",
  "txt-effectiveness-percents": "93% effective with typical use, 98% effective with perfect use",
  "txt-effectiveness-remains-the-same": "Effectiveness remains the same across all devices",
  "txt-eligible-apple-watch": "Does it have temperature sensors?",
  "txt-EligibleAppleWatchOption--no": "No",
  "txt-EligibleAppleWatchOption--yes": "Yes",
  "txt-email-verified": "Great! Your email has been verified",
  "txt-emails-agree": "I would like to receive emails about women’s health, newsletters, and promotional offers.",
  "txt-emails-agree-shorter": "I would like to receive emails about women’s health and Natural Cycles.",
  "txt-emotional-changes": "Most of us experience emotional and physical changes during our cycles",
  "txt-emotions-changes": "What emotions do you experience?",
  "txt-EmotionsOption--anger": "Anger",
  "txt-EmotionsOption--anxiety": "Anxiety",
  "txt-EmotionsOption--calmness": "Calmness",
  "txt-EmotionsOption--confidence": "Confidence",
  "txt-EmotionsOption--energyLevels": "Energetic",
  "txt-EmotionsOption--happiness": "Happiness",
  "txt-EmotionsOption--irritability": "Irritability",
  "txt-EmotionsOption--moodSwings": "Mood swings",
  "txt-EmotionsOption--numbness": "Numbness",
  "txt-EmotionsOption--PMS": "PMS",
  "txt-EmotionsOption--sensitivity": "Sensitivity",
  "txt-EmotionsOption--stress": "Stress",
  "txt-EmotionsOption--tiredness": "Tiredness",
  "txt-equally-effective": "The NC° app is equally effective with all three devices",
  "txt-estimated-tax": "Estimated Tax",
  "txt-extra-supplies": "Extra supplies",
  "txt-favorite-way-to-unwind": "What’s your favorite way to unwind?",
  "txt-FavoriteWayToUnwindOption--exercising": "Exercising",
  "txt-FavoriteWayToUnwindOption--havingABath": "Having a bath",
  "txt-FavoriteWayToUnwindOption--hobbies": "Hobbies",
  "txt-FavoriteWayToUnwindOption--meditating": "Meditating",
  "txt-FavoriteWayToUnwindOption--music": "Music",
  "txt-FavoriteWayToUnwindOption--other": "Other",
  "txt-FavoriteWayToUnwindOption--reading": "Reading",
  "txt-FavoriteWayToUnwindOption--selfCare": "Self-care",
  "txt-FavoriteWayToUnwindOption--timeWithFriends": "Time with friends",
  "txt-fda-cleared": "FDA Cleared for use with 3 devices!",
  "txt-fda-cleared-long": "First FDA Cleared and CE marked birth control app",
  "txt-fda-cleared-short": "FDA Cleared & certified in Europe",
  "txt-feeling-healthier": "88% of users said they feel healthier since switching to NC°",
  "txt-feeling-stressed": "How often do you feel stressed?",
  "txt-FeelingStressedOption--always": "Almost always",
  "txt-FeelingStressedOption--noStress": "Stress? What stress!",
  "txt-FeelingStressedOption--timeToTime": "From time to time",
  "txt-fertile-window": "NC° finds your fertile window to help you get pregnant faster",
  "txt-fertile-window-myth": "You’re only fertile 6 days during your cycle",
  "txt-fertility-goal": "What’s your fertility goal?",
  "txt-fertility-help": "What can we help you with?",
  "txt-fertility-link": "There is a link between your temperature and fertility",
  "txt-fertility-myth": "True or false? Fertility myths busted with NC°",
  "txt-find-my-device": "Find my device",
  "txt-find-ovulation": "Knowing this helps us find your ovulation faster",
  "txt-find-right-match": "Let’s find the right match",
  "txt-finding-ovulation": "Finding ovulation...",
  "txt-for": "for",
  "txt-free-delivery-all": "Free delivery on all NC products!",
  "txt-free-thermometer": "Free thermometer",
  "txt-free-with-annual": "Free with annual plan",
  "txt-free-with-annual-plan": "Free with annual plan purchase",
  "txt-future-subscription": "Your upcoming plan",
  "txt-go-hormone-free": "Go hormone-free",
  "txt-goal--1": "NC° Birth Control",
  "txt-goal--2": "NC° Plan Pregnancy",
  "txt-goal--3": "NC° Follow Pregnancy",
  "txt-goal--4": "NC° Recovery",
  "txt-goal--5": "NC° Postpartum",
  "txt-goal-select": "How would you like to start using Natural Cycles?",
  "txt-GoalBreakpointOption--improveWellbeing": "Improve well-being",
  "txt-GoalBreakpointOption--improveWellbeing-new": "Improve well-being",
  "txt-GoalBreakpointOption--manageSymptoms": "Manage symptoms",
  "txt-GoalBreakpointOption--manageSymptoms-new": "Manage symptoms",
  "txt-GoalBreakpointOption--plan": "I want to get pregnant now",
  "txt-GoalBreakpointOption--plan-alt": "Get pregnant faster",
  "txt-GoalBreakpointOption--plan-new": "Get pregnant faster",
  "txt-GoalBreakpointOption--postPartum": "I want support after childbirth",
  "txt-GoalBreakpointOption--postPartum-alt": "Recover from childbirth",
  "txt-GoalBreakpointOption--postPartum-new": "Recover from childbirth",
  "txt-GoalBreakpointOption--prevent": "I want to prevent pregnancy",
  "txt-GoalBreakpointOption--prevent-alt": "Prevent pregnancy",
  "txt-GoalBreakpointOption--prevent-new": "Prevent pregnancy",
  "txt-GoalBreakpointOption--preventNowPlanLater": "I want to prevent now and plan later",
  "txt-GoalBreakpointOption--preventNowPlanLater-alt": "Prevent now, plan later",
  "txt-GoalBreakpointOption--preventNowPlanLater-new": "Prevent now, plan later",
  "txt-GoalBreakpointOption--trackCycle-alt": "Track my cycle",
  "txt-GoalBreakpointOption--trackCycle-new": "Track my cycle",
  "txt-GoalBreakpointOption--trackPregnancy": "I want to track my pregnancy",
  "txt-GoalBreakpointOption--trackPregnancy-alt": "Track my pregnancy",
  "txt-GoalBreakpointOption--trackPregnancy-new": "Track my pregnancy",
  "txt-GoalBreakpointOption--undecided": "I haven't made my mind up yet",
  "txt-GoalBreakpointOption--undecided-alt": "Haven't decided yet",
  "txt-GoalBreakpointOption--undecided-new": "Haven't decided yet",
  "txt-good-fit-plan": "Our FDA Cleared fertility-finding technology is a good fit for you!",
  "txt-good-fit-prevent": "Our FDA Cleared, hormone-free birth control is a good fit for you!",
  "txt-google-login": "Google Login",
  "txt-have-you-had-side-effects": "Have you experienced any side effects while on hormonal birth control?",
  "txt-health-data": "Health data",
  "txt-health-goals": "Do you have other health goals in mind?",
  "txt-HealthGoalsOption--diet": "Diet",
  "txt-HealthGoalsOption--mentalHealth": "Mental Health",
  "txt-HealthGoalsOption--physicalActivity": "Physical Activity",
  "txt-HealthGoalsOption--screenTime": "Screen time",
  "txt-HealthGoalsOption--sleepQuality": "Sleep quality",
  "txt-HealthGoalsOption--stress": "Stress",
  "txt-help-find-device": "Help me find a device",
  "txt-help-us-knowing-the-device": "This helps us know what’s comfortable for you",
  "txt-help-us-knowing-your-comfort": "This helps us know what’s comfortable for you",
  "txt-hormon-free-long": "Hormone-free birth control that’s side-effect-free",
  "txt-hormon-free-short": "Hormone-free with no side effects",
  "txt-HormonalBirthControlHowLongBreakpointOption--fewMonths": "A few months",
  "txt-HormonalBirthControlHowLongBreakpointOption--lessThanAMonth": "Less than a month",
  "txt-HormonalBirthControlHowLongBreakpointOption--moreThanAYear": "More than a year",
  "txt-HormonalBirthControlUsageBreakpointOption--lastMonth": "Yes, in the last month",
  "txt-HormonalBirthControlUsageBreakpointOption--monthsAgo": "Yes, but several months ago",
  "txt-HormonalBirthControlUsageBreakpointOption--no": "No",
  "txt-hormone-free-zero-side-effects": "NC° is non-hormonal and has zero side effects",
  "txt-how-do-you-sleep": "How do you sleep?",
  "txt-how-long-conceive": "How long have you been trying to conceive?",
  "txt-how-long-hormonal-bc": "How long have you been using hormonal birth control?",
  "txt-how-regular": "How regular is your period?",
  "txt-im-sure": "I am sure",
  "txt-in-just-five-minutes": "In just 5 minutes, find out how we can help you prevent or plan pregnancy",
  "txt-included": "Included",
  "txt-included-in-annual-plan": "Included in the annual plan",
  "txt-irregular-cycle-plan": "NC° can help you get pregnant when your cycle is irregular",
  "txt-irregular-cycle-prevent": "Your cycle is irregular, but Natural Cycles is just as effective for you!",
  "txt-joined": "Joined",
  "txt-keep-therm": "Would you like to keep the thermometer in your cart?",
  "txt-know-body": "98% of Cyclers said NC° helped them to know their body better",
  "txt-know-pregnant-days": "Do you know the days you can get pregnant?",
  "txt-know-your-habits": "This helps us find the right device for you",
  "txt-knowing-your-age": "Knowing your age helps us tailor your journey",
  "txt-knowing-your-sex-drive": "How well do you know your sex drive?",
  "txt-KnowingYourSexDriveOption--notSure": "I’m not sure",
  "txt-KnowingYourSexDriveOption--notVeryWell": "Not very well",
  "txt-KnowingYourSexDriveOption--veryWell": "Very well",
  "txt-KnowPregnantDays--no": "No",
  "txt-KnowPregnantDays--notSure": "I’m not sure",
  "txt-KnowPregnantDays--yes": "Yes",
  "txt-last-period-start": "When did your last period start?",
  "txt-learn-about-sex": "Let's learn about your sex drive and the best time to conceive",
  "txt-libido-changes": "What libido changes do you experience?",
  "txt-LibidoChangesOption--highSexDrive": "High sex drive",
  "txt-LibidoChangesOption--lowSexDrive": "Low sex drive",
  "txt-LibidoChangesOption--other": "Other",
  "txt-login": "log in",
  "txt-logout": "Log out",
  "txt-logout-other-devices": "Log out from other devices",
  "txt-maximum": "Maximum",
  "txt-measure-day": "Measure in the morning",
  "txt-measure-overnight": "Measure overnight",
  "txt-measure-sooner": "This can help you start measuring sooner",
  "txt-medical-conditions": "NC° works with your condition. We’re here to support you!",
  "txt-MedicalConditionsBreakpointOption--endometriosis": "Endometriosis",
  "txt-MedicalConditionsBreakpointOption--pcos": "Polycystic ovary syndrome (PCOS)",
  "txt-MedicalConditionsBreakpointOption--thyroid": "Thyroid condition",
  "txt-menstrual-cycle": "Natural Cycles pinpoints your fertile window and lets you know the best days to conceive",
  "txt-millions": "million",
  "txt-minimum": "Minimum",
  "txt-mode": "Mode",
  "txt-name": "Name",
  "txt-natural-method": "Our algorithm analyzes hormone-driven temp shifts to find ovulation",
  "txt-nearly-there": "Nearly there, {{name}}",
  "txt-need-itemized-receipt": "Need an itemized receipt?",
  "txt-never-share-data": "We never share your data",
  "txt-new": "New",
  "txt-new-apple-watch-purchase": "Do you plan to purchase a new watch?",
  "txt-new-device-available": "New device available",
  "txt-new-password": "New password",
  "txt-next": "Next",
  "txt-next-payment-date": "Next payment date",
  "txt-nice-to-meet-you": "Nice to meet you, {{name}}!",
  "txt-night-sleep": "Do you sleep well at night?",
  "txt-NightSleepOption--oftenWakesUp": "No, I often wake up at night",
  "txt-NightSleepOption--sleepsThroughTheNight": "Yes, I sleep through the night",
  "txt-NightSleepOption--sometimesWakesUp": "Yes, but sometimes I wake up in the night",
  "txt-no-active-subscription": "No active subscription",
  "txt-no-data-shared": "No data is shared with Apple without your consent",
  "txt-no-thanks": "No thanks",
  "txt-non-invasive-no-side-effects": "NC° is non-invasive with no side effects",
  "txt-not-alone": "You are not alone: 9 out of 10 of our users suspect their side effects were caused by hormonal methods",
  "txt-not-confirmed": "Not confirmed",
  "txt-not-eligible-yet": "Sorry, you’re not eligible yet",
  "txt-one-time-payment": "One time payment",
  "txt-only-fda-cleard": "Discover the only FDA Cleared birth control app",
  "txt-only-temperature-sensors": "Series 8, Series 9, and all models of Apple Watch Ultra contain two built-in temperature sensors",
  "txt-open-to-sleeping-with-watch": "Would you be open to sleeping with your watch on?",
  "txt-oura-selection": "Oura Ring Selection",
  "txt-oura-tax-info": "Excited about your Oura Ring? Once you’ve purchased our bundle, head over to Oura’s website to select your Oura Ring size and color! \n<br /><br />\nYou can also upgrade to any premium Oura Ring style and color on Oura's website for an added cost. Additional taxes may be applied depending on your location.",
  "txt-ovulation-temprature": "Your body temperature rises slightly after ovulation",
  "txt-pain-changes": "What type of pain do you experience?",
  "txt-partner-insight": "We’ll help your partner learn too if you want us to",
  "txt-partner-involvement": "How involved does your partner want to be in planning pregnancy?",
  "txt-PartnerInvolvementOption--false": "We’re here to support you throughout this journey",
  "txt-PartnerInvolvementOption--noPartner": "I don’t have a partner",
  "txt-PartnerInvolvementOption--notInvolved": "They don’t want to be involved",
  "txt-PartnerInvolvementOption--someWhatInvolved": "They want to be somewhat involved",
  "txt-PartnerInvolvementOption--true": "You can share the responsibility of planning with NC° Partner View",
  "txt-PartnerInvolvementOption--veryInvolved": "They want to be very involved",
  "txt-payment": "Payment",
  "txt-payment-agree": "I agree to the terms above",
  "txt-payment-agree-below": "I agree to the terms below",
  "txt-payment-methods": "Payment methods",
  "txt-perfect-use": "Perfect use",
  "txt-PeriodRegularityOption--canSometimesPredict": "I can sometimes predict it",
  "txt-PeriodRegularityOption--canUsuallyPredict": "I can usually predict it to the day",
  "txt-PeriodRegularityOption--hardToPredict": "It’s hard to predict",
  "txt-plan--HALFYEAR": "Half year plan",
  "txt-plan--MONTH": "Monthly plan",
  "txt-plan--QUARTER": "Quarterly plan",
  "txt-plan--ROLLING_TRIAL": "Trial plan",
  "txt-plan--ROLLING_TRIAL_7DAYS": "Trial",
  "txt-plan--TEMP": "Temporary subscription",
  "txt-plan--TRIAL": "Trial plan",
  "txt-plan--YEAR": "Yearly plan",
  "txt-plans": "Plans",
  "txt-plans-page-reviews-PLAN": "“I can’t thank Natural Cycles enough for helping decode the minefield that is fertility!”",
  "txt-plans-page-reviews-PLAN--name": "Shadow Jackson",
  "txt-plans-page-reviews-PREVENT": "“4 years in, 0 pregnancies. I know my cycle inside & out and it's down to Natural Cycles.”",
  "txt-plans-page-reviews-PREVENT--name": "Gina Cockcroft",
  "txt-plans-survey--PLAN-desc": "of users said Natural Cycles has helped them understand their body better",
  "txt-plans-survey--PLAN-stat": "98%",
  "txt-plans-survey--POSTPARTUM-desc": "users felt the NC° app supported them on their fertility journey",
  "txt-plans-survey--POSTPARTUM-stat": "9 in 10",
  "txt-plans-survey--PREGNANT-desc": "of users said Natural Cycles has helped them understand their body better",
  "txt-plans-survey--PREGNANT-stat": "98%",
  "txt-plans-survey--PREVENT-desc": "of our users said they’re happy they made the switch to non-hormonal birth control",
  "txt-plans-survey--PREVENT-stat": "99%",
  "txt-plans-survey-source": "Natural Cycles User Survey (Feb 2024), N:>2200 Cyclers.",
  "txt-plans-view-all": "View all plans",
  "txt-postal-address": "Postal address",
  "txt-power-on-you-long": "Learn your cycle with tailored updates and insights",
  "txt-power-on-you-short": "Puts the power in your hands",
  "txt-pregnancy-myth": "You can get pregnant any time of the month",
  "txt-pregnancy-myth-question": "You can get pregnant any time of the month",
  "txt-pregnant-in-three-cycles": "Natural Cycles users get pregnant in 3 cycles or less on average*",
  "txt-products": "Products",
  "txt-profile": "My profile",
  "txt-purchase-from": "From",
  "txt-purchase-include": "Included with annual plan",
  "txt-purchase-separate": "Purchased separately",
  "txt-PurchaseNewWatchOption--no": "No",
  "txt-PurchaseNewWatchOption--yes": "Yes",
  "txt-QUARTER": "3 Months",
  "txt-receipt": "Receipt",
  "txt-receipts": "Receipts",
  "txt-recommend-best-device": "Based on your sleep and routine, we recommend",
  "txt-recovery-key": "Recovery key",
  "txt-regular-cycle-plan": "Your cycle is regular! This helps us find your fertile days faster!",
  "txt-regular-cycle-prevent": "Your cycle is regular! This helps us find your fertile days faster!",
  "txt-remove": "Remove",
  "txt-repeat-new-password": "Repeat new password",
  "txt-result-slide-1": "We've helped over 3 million users in 160+ countries for more than 10 years",
  "txt-result-slide-2": "When asked, 95% of Cyclers said they would recommend Natural Cycles",
  "txt-result-slide-3": "Rated 4.8 stars with over 40k ratings on the App Store",
  "txt-reviews": "reviews",
  "txt-save": "Save",
  "txt-select": "Select",
  "txt-sex-drive": "Your sex drive can change throughout your cycle, discover your unique patterns with NC°",
  "txt-sex-frequency": "How often do you have sex?",
  "txt-SexFrequency--coupleTimesMonth": "A couple times a month",
  "txt-SexFrequency--everyday": "Everyday",
  "txt-SexFrequency--fewTimesWeek": "A few times a week",
  "txt-SexFrequency--lessThanOnceMonth": "Less than once a month",
  "txt-SexFrequency--ratherNotSay": "I’d rather not say",
  "txt-shipping": "Shipping",
  "txt-SideEffectsBreakpointOption--depression": "Depression",
  "txt-SideEffectsBreakpointOption--headaches": "Headaches",
  "txt-SideEffectsBreakpointOption--heavierPeriods": "Heavier periods",
  "txt-SideEffectsBreakpointOption--irregularBleeding": "Irregular bleeding",
  "txt-SideEffectsBreakpointOption--lossOfLibido": "Loss of libido",
  "txt-SideEffectsBreakpointOption--moodSwings": "Mood swings",
  "txt-SideEffectsBreakpointOption--skin": "Skin changes",
  "txt-SideEffectsBreakpointOption--tenderBreasts": "Tender breasts",
  "txt-SideEffectsBreakpointOption--weightGain": "Weight gain",
  "txt-sign-up-here": "Sign up here",
  "txt-sit-tight": "Sit tight! We’re setting up your personalized fertility journey",
  "txt-skin-changes": "What skin changes do you experience?",
  "txt-SkinChangesOption--acne": "Acne",
  "txt-SkinChangesOption--dryness": "Dryness",
  "txt-SkinChangesOption--glowy": "Glowy",
  "txt-SkinChangesOption--oiliness": "Oiliness",
  "txt-SkinChangesOption--puffiness": "Puffiness",
  "txt-skip-free-therm": "No, I’m ready to resubscribe",
  "txt-sleep-consistency": "How consistent are your sleep patterns?",
  "txt-sleep-consistency-clarifier": "By consistent, we mean 5 out of 7 days a week",
  "txt-sleep-with-ring-or-watch": "Do you sleep wearing a ring or watch?",
  "txt-SleepConsistencyOption--irregularSleep": "My sleep is irregular",
  "txt-SleepConsistencyOption--patternsFluctuate": "My sleep patterns fluctuate",
  "txt-SleepConsistencyOption--regularHours": "I usually sleep regular hours",
  "txt-SleepOption--sleepThroughTheNight": "I usually sleep through the night",
  "txt-SleepOption--sleepVaries": "My sleep varies",
  "txt-SleepOption--wakeUpOften": "I wake up often",
  "txt-SleepWithTrackerOption--neither": "I wear neither",
  "txt-SleepWithTrackerOption--yesWithBoth": "I wear both",
  "txt-SleepWithTrackerOption--yesWithRing": "Yes, I wear a ring to bed",
  "txt-SleepWithTrackerOption--yesWithWatch": "Yes, I wear a watch or fitness tracker",
  "txt-some-conditions-affect-cycle": "Some conditions may affect the menstrual cycle",
  "txt-spending-days-info": "Now that we know about your nights, tell us more about how you spend your days",
  "txt-stars": "stars",
  "txt-starting-exciting-journey": "You’re starting an exciting journey We’re here for you",
  "txt-StatementDescriptionOption--notARoutinePerson": "“I’m not a routine person”",
  "txt-StatementDescriptionOption--routinesAreImportant": "“Routine is very important to me”",
  "txt-StatementDescriptionOption--sometimesIHaveRoutines": "“Sometimes I like to have a routine”",
  "txt-step-one": "Step 1. Choose your plan",
  "txt-step-two": "Step 2. Choose your hardware",
  "txt-study-on-users": "*Based on a study of 5,376 Natural Cycles users",
  "txt-subscribe": "Subscribe",
  "txt-subscription": "Subscription",
  "txt-subscription-payment-required": "We can't process your subscription's payment. Please try to pay again with your current payment method or add a new one.",
  "txt-subscription-successfully-cancelled": "Subscription successfully cancelled",
  "txt-subtotal": "Subtotal",
  "txt-summary-page": "You will get a confirmation email as soon as your order has been processed.",
  "txt-supporting": "Supporting",
  "txt-switch-and-know-when-fertile": "Switch to NC° to know if today is a fertile day",
  "txt-switch-and-use-condoms": "Switch to NC° and only use condoms on fertile days!",
  "txt-switch-from-hormons": "Switch from hormonal birth control and discover your natural cycle",
  "txt-tailored-updates": "You’ll get tailored updates about your baby’s development with NC° Follow Pregnancy",
  "txt-take-control": "Take control of your fertility today!",
  "txt-take-look-your-cycle": "Next, let’s take a look at your menstrual cycle",
  "txt-tell-us-how-you-sleep": "This helps us understand your sleep patterns",
  "txt-test-knowledge": "Test my knowledge",
  "txt-testimonial": "We’ve helped 160,000+ couples conceive",
  "txt-testimonial-åsa": "I was told I would have problems getting pregnant... because of Natural Cycles I was able to conceive this fantastic little man💜",
  "txt-testimonial-ashley": "All of these ‘side effects’ that I didn’t even realize weren’t normal are gone and I feel like me again!!",
  "txt-testimonial-jess": "I love it so far! My cycles are generally regular and NC is SO spot on! I’ve learned so much about my body",
  "txt-testimonial-review": "When we were ready to start trying for a baby, we used NC° Plan Pregnancy and got pregnant on the first try!",
  "txt-testimonial-reviewer": "Lauren",
  "txt-testimonial-reviewer-date": "Joined Natural Cycles in 2021",
  "txt-testimonial-shadow": "NC° helped me understand my body, cycle, and the best time of the month to get pregnant.",
  "txt-testimonial-shelby": "Using NC is a game changer with irregular cycles. Knowing what phase I’m in eases my anxiety during an extra long cycle.",
  "txt-thank-you": "Thank you, {{name}}",
  "txt-thank-you-wonderful": "Thanks for being so open, {{name}}",
  "txt-thanks-for-sharing": "Thanks for sharing, {{name}}",
  "txt-these-apple-watches-are-eligible": "Natural Cycles has received FDA Clearance to use wrist temperature from Apple Watch Series 8+",
  "txt-to-young": "To use Natural Cycles you must be 18+. Please keep us in mind for the future💜",
  "txt-total": "Total",
  "txt-track-device-nc": "Track your temperature with a device that connects to NC°",
  "txt-tracker-video": "NC° tracks your body temp and determines each fertile day",
  "txt-tracking-info": "Get PMS reminders, personalized insights and more by tracking your cycle",
  "txt-trial": "Trial",
  "txt-trouble-getting-back-to-sleep": "When you awake in the night, do you have trouble getting back to sleep?",
  "txt-TroubleGettingBackToSleepOption--noTrouble": "No, I usually fall back to sleep quickly",
  "txt-TroubleGettingBackToSleepOption--oftenAwakeAWhile": "Yes, I'm often awake for a while",
  "txt-TroubleGettingBackToSleepOption--someStruggle": "Yes, I sometimes struggle to fall back to sleep",
  "txt-TypeOfPainOption--backache": "Backache",
  "txt-TypeOfPainOption--cramps": "Cramps",
  "txt-TypeOfPainOption--headache": "Headache",
  "txt-TypeOfPainOption--ovulationPain": "Ovulation pain",
  "txt-TypeOfPainOption--soreBreasts": "Sore breasts",
  "txt-typical-use": "Typical use",
  "txt-understand-cycle-regularity": "This helps us understand your cycle regularity",
  "txt-update-subscription": "Update subscription",
  "txt-update-upcoming-sub": "Update upcoming subscription",
  "txt-upgrade-now": "Upgrade now",
  "txt-used-hormonal-bc": "Have you recently used hormonal birth control?",
  "txt-user-survey-feb-2024": "Natural Cycles User Survey (February 2024), N:>2200 Cyclers.",
  "txt-verification-amount-warning": "For security purposes, the <b>total amount before discount must be verified</b> at the payment step. Your account will only be charged the discounted amount of <b>{{amount}}</b>, but the original total will be shown during payment authentication.",
  "txt-verification-amount-warning--ROLLING_TRIAL": "In order to set up a secure payment profile with your bank, the <b>total amount of the yearly subscription that will automatically activate at the end of the trial period must be verified</b>. However, you will only be charged <b>{{amount}}</b> as displayed in your cart.",
  "txt-verify-card": "Verify card",
  "txt-want-a-free-thermometer": "Want a free thermometer?",
  "txt-we-make-it-easy": "We make fertility tracking easy",
  "txt-wear-watch-to-bed": "Do you plan to wear your watch to bed?",
  "txt-wearing-wearable": "Do you sleep with a ring or watch on?",
  "txt-WearWatchToBedOption--dontKnow": "I don't know",
  "txt-WearWatchToBedOption--no": "No",
  "txt-WearWatchToBedOption--yes": "Yes",
  "txt-welcome": "Welcome, {{name1}} {{name2}}!",
  "txt-welcome-back": "Hey there, welcome back!",
  "txt-welcome-no-name": "Welcome",
  "txt-what-is-your-goal": "What’s your fertility goal?",
  "txt-what-is-your-name": "Hey there! What’s your name?",
  "txt-when-birthday": "When is your birthday?",
  "txt-which-statement": "Which statement best describes you?",
  "txt-which-wearable-in-list": "Which of these device(s) do you own?",
  "txt-whichWearable--appleWatch": "Apple Watch",
  "txt-whichWearable--ouraRing": "Oura Ring",
  "txt-wonderful": "Wonderful, {{name}}💜",
  "txt-year": "year",
  "txt-your-journey": "Select the main method you’re using right now",
  "txt-your-measuring-device": "Choose from 3 devices that connect with the FDA Cleared app, Natural Cycles",
  "txt-your-name": "Your name",
  "txt-your-purchase": "Your purchase",
  "txt-your-setup-is-saved": "Your customized setup is saved for the next few minutes! Create an account to get started",
  "unavailable-cart-item-short-txt": "There's an item in the cart that we can't ship to the selected country",
  "unavailable-cart-item-title": "Unavailable cart item",
  "unavailable-cart-item-txt": "There is an item in your cart that we can't ship to the selected country. Please review your selection!",
  "usp-approved": "FDA Cleared + CE Marked",
  "usp-bluetooth-thermometer": "NC° Thermometer to sync with the app via bluetooth",
  "usp-effective": "93% Effective with Typical Use",
  "usp-follow-guided-updates": "Guided week-by-week updates",
  "usp-follow-headline": "Choose a subscription to start following your pregnancy",
  "usp-follow-learn": "Learn about the changes in your body",
  "usp-follow-modes": "Access to more Natural Cycles modes after pregnancy",
  "usp-follow-track": "Track your baby's growth & development",
  "usp-fsa-covered": "FSA Covered",
  "usp-hormone-free": "100% Hormone Free",
  "usp-oura-continuous-measuring": "Continuous measuring during the night",
  "usp-plan-headline": "Start your planning journey by choosing a subscription",
  "usp-plan-irregular": "Works with irregular cycles",
  "usp-plan-ovulation": "Detects and predicts ovulation day",
  "usp-plan-science": "Backed by science & research",
  "usp-plan-tests": "Prompts pregnancy & ovulation tests",
  "usp-postPartum-care": "Cares for you while you care for your baby",
  "usp-postPartum-health": "Daily health and well-being check-ins",
  "usp-postPartum-resources": "Expert support and resources",
  "usp-prevent-approved": "FDA Cleared + CE Marked",
  "usp-prevent-approved-us": "FDA Cleared",
  "usp-prevent-daily": "Includes daily fertility status & predictions",
  "usp-prevent-headline": "Select your subscription to digital birth control",
  "usp-prevent-hormone-free": "100% Hormone Free",
  "usp-prevent-hormone-free-bc": "100% Hormone-free birth control",
  "usp-prevent-tailored": "Tailored PMS alerts, notifications & more",
  "validation-address-required": "Address is required",
  "validation-city-required": "City is required",
  "validation-country-required": "Please select country",
  "validation-name-required": "Name is required",
  "validation-region-required": "Please select region",
  "verify-email-description": "To finish setting up your account, please verify your email by clicking on the button below",
  "verify-email-header": "Welcome to Natural Cycles!",
  "verify-email-later": "Later",
  "verify-email-open-inbox": "Open my inbox",
  "verify-email-resend": "Resend email",
  "verify-email-resend-in": "Re-send email in {{time}}",
  "your-order": "Your order",
  "your-purchase": "Your purchase"
}
