import { inject } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { _isEmpty } from '@naturalcycles/js-lib'
import { quizParentSlug } from '@src/app/constants/quiz'
import { BackdoorDevicesService } from '@src/app/core/services/backdoorDevices.service'
import { QuizService } from '@src/app/core/services/quiz/quiz.service'
import { SignalStore } from '@src/app/core/store/signalStore'
import { hasParentPage } from '../core/util/nav.util'
import { Page, ParentPage } from '../shared/typings/enum/pages'
import { QuizName } from '../shared/typings/interfaces/quiz'

export const canActivateQuiz: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router)
  const quizService = inject(QuizService)
  const backdoorDevicesTest = inject(BackdoorDevicesService)
  const store = inject(SignalStore)

  const { $lang, $quiz } = store
  const lang = $lang()
  const quiz = $quiz()

  const isOnClearblueRoute = hasParentPage(ParentPage.clearblue, state.url)
  if (isOnClearblueRoute && quiz.name !== QuizName.clearblue) {
    store.$quiz.set({
      name: QuizName.clearblue,
      data: {},
      flow: quizService.getEngine().getInitialFlow(),
    })
  } else if (!isOnClearblueRoute && quiz.name === QuizName.clearblue) {
    store.$quiz.set({
      name: QuizName.default,
      data: {},
      flow: quizService.getEngine().getInitialFlow(),
    })
  }

  const getQuizPageTree = (page: Page): UrlTree => {
    const parentPath = state.url.split(quizParentSlug)[0]
    return router.createUrlTree([`${parentPath}/${quizParentSlug}/${page}`])
  }

  if (backdoorDevicesTest.isParticipant()) {
    return router.createUrlTree([`${lang}/${Page.measuringDevice}`])
  }

  const page: Page = route.data['page']

  if (page === Page.lastQuizPage) {
    return getQuizPageTree(quizService.getEngine().getLastPageInFlow(quiz.flow) || ('' as Page))
  }

  const redirectPage = quizService.getEngine().canActivateQuizPage(page, quiz.flow, quiz.data)

  if (!redirectPage) {
    // All conditions are met for the user to view the current page
    return true
  }
  const firstPage = quizService.getEngine().getFirstPageInFlow(quiz.flow)

  // Redirect to the first page in the quiz flow if there is no quiz data
  // We do this because if we navigate backwards to the quiz from further in the flow,
  // we end at the name page, which is not the first page in the flow.
  // This is because the name page doesn't 'require' any data from the intro page.
  if (_isEmpty(quiz.data) && page !== firstPage) {
    return getQuizPageTree(firstPage)
  }

  return getQuizPageTree(redirectPage)
}
