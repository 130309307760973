import { Injectable } from '@angular/core'
import { loadScript } from '@naturalcycles/frontend-lib'
import { _errorDataAppend } from '@naturalcycles/js-lib'
import { sentry } from '@src/app/core/util/sentry.util'
import { env } from '@src/environments/environment'

@Injectable({ providedIn: 'root' })
export class CookiebotService {
  async load(): Promise<void> {
    if (!env.cookiebotId) return
    sentry.addBreadcrumb({ message: 'CookiebotService load' })
    window.addEventListener('CookiebotOnDialogInit', () => this.handlePresetParams(), false)

    try {
      await loadScript(`https://consent.cookiebot.com/uc.js?cbid=${env.cookiebotId}`)
    } catch (err) {
      sentry.captureException(_errorDataAppend(err, { fingerprint: env.cookiebotId }))
    }
  }

  handlePresetParams(): void {
    const cookiebot = window['Cookiebot']
    const qs = new URLSearchParams(location.search)
    const cp = qs.get('cp')
    const cs = qs.get('cs')
    const cm = qs.get('cm')
    const didPassOnConsent = cp !== null && cs !== null && cm !== null

    if (!cookiebot && didPassOnConsent) {
      sentry.captureMessage(
        `Cookiebot isn't loaded, consent cannot be passed on ${location.search}`,
      )
    }
    if (!cookiebot || cookiebot.hasResponse) return

    // We don't want to show the cookiebot popup if the user already has consented
    if (didPassOnConsent) {
      cookiebot.submitCustomConsent(cp === 'true', cs === 'true', cm === 'true')
    }
  }
}
