import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { initSentry } from '@src/app/core/services/sentry.service'
import { AppModule } from 'app/app.module'
import { env } from 'environments/environment'

if (env.angularProd) {
  enableProdMode()
}

initSentry()

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line unicorn/prefer-top-level-await
  .catch(err => console.error(err))
