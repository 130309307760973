import { inject, Injectable } from '@angular/core'
import { getState } from '@src/app/core/store/signalStore'
import { CartService } from '../core/services/cart.service'
import { RouteGuard } from '../core/services/route-guard.service'
import { Page } from '../shared/typings/enum/pages'

@Injectable({ providedIn: 'root' })
export class CanActivateActivateOrderSummary extends RouteGuard {
  private cartService = inject(CartService)

  canActivate(): boolean {
    const { cart } = getState()
    const hasActivationCodeApplied = this.cartService.hasActivationCodeApplied(cart)
    const requiresPayment = this.cartService.requiresPayment(cart)

    if (!hasActivationCodeApplied || requiresPayment || !this.hasAccountId()) {
      void this.navService.goToPage([Page.code])
    }
    return true
  }
}
