import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { HardwareId, RegistrationFlow } from '@naturalcycles/shared'
import { RouteGuard } from '../core/services/route-guard.service'
import { Page } from '../shared/typings/enum/pages'
import { QueryParam } from '../shared/typings/enum/query-params'

@Injectable({
  providedIn: 'root',
})
export class CanActivateOuraPage extends RouteGuard {
  override page = Page.oura

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const navParams = {
      queryParams: {
        ...route.queryParams,
        [QueryParam.flow]: RegistrationFlow.OURA.toString(),
        [QueryParam.measuringDevice]: HardwareId.OURA,
      },
    }

    void this.navService.goToPage([Page.measuringDevice], navParams)
    return false
  }
}
