import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router'
import { _isEmpty } from '@naturalcycles/js-lib'
import { Experiment } from '@naturalcycles/shared'
import { quizParentSlug } from '@src/app/constants/quiz'
import { BackdoorDevicesService } from '@src/app/core/services/backdoorDevices.service'
import { ExperimentService } from '@src/app/core/services/experiment.service' // Import the ExperimentService
import { QuizService } from '@src/app/core/services/quiz/quiz.service'
import { SignalStore } from '@src/app/core/store/signalStore'
import { Page } from '../shared/typings/enum/pages'

export const canActivateQuiz: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
) => {
  const router = inject(Router)
  const quizService = inject(QuizService)
  const backdoorDevicesTest = inject(BackdoorDevicesService)
  const store = inject(SignalStore)
  const experimentService = inject(ExperimentService)

  const { $lang, $quiz } = store
  const lang = $lang()
  const quiz = $quiz()

  if (backdoorDevicesTest.isParticipant()) {
    return router.createUrlTree([`${lang}/${Page.measuringDevice}`])
  }

  const page: Page = route.data['page']

  // 278 = test switching the start quiz intro page for privacy page
  const firstPage = quizService.getEngine().getFirstPageInFlow(quiz.flow)
  if (page === firstPage) {
    void experimentService.logImpression(Experiment.REMOVE_START_QUIZ_PAGE)
  }

  if (page === Page.lastQuizPage) {
    // if user tries to access the "placeholder" last page of the quiz, we redirect to the actual last page
    return router.createUrlTree([
      `${lang}/${quizParentSlug}/${quizService.getEngine().getLastPageInFlow(quiz.flow)}`,
    ])
  }

  const redirectPage = quizService.getEngine().canActivateQuizPage(page, quiz.flow, quiz.data)

  if (!redirectPage) {
    // All conditions are met for the user to view the current page
    return true
  }

  // Redirect to the first page in the quiz flow if there is no quiz data
  // We do this because if we navigate backwards to the quiz from further in the flow,
  // we end at the name page, which is not the first page in the flow.
  // This is because the name page doesn't 'require' any data from the intro page.
  if (_isEmpty(quiz.data)) {
    const firstPage = quizService.getEngine().getFirstPageInFlow(quiz.flow)
    if (firstPage && page !== firstPage) {
      return router.createUrlTree([`${lang}/${quizParentSlug}/${firstPage}`])
    }
  }

  return router.createUrlTree([`${lang}/${quizParentSlug}/${redirectPage}`])
}
