import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule, Routes } from '@angular/router'
import { LANG_DEFAULT } from '@naturalcycles/shared'
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { ButtonComponentModule } from '@src/app/components/button/button.component.module'
import { ModalModule } from '@src/app/components/modals/modal.module'
import { NavbarComponent } from '@src/app/components/navbar/navbar.component'
import { CoreModule } from '@src/app/core/core.module'
import { AppMissingTranslationHandler } from '@src/app/missingTranslationHandler'
import { PagesModule } from '@src/app/pages/pages.module'
import { AppTranslateLoader } from '@src/app/translateLoader'
import { env } from '@src/environments/environment'
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha'
import { NgxMaskModule } from 'ngx-mask'
import { AppComponent } from './app.component'
import { sentry } from './core/services/sentry.service'
import { SharedModule } from './shared/shared.module'

const APP_ROUTES: Routes = []

@NgModule({
  declarations: [AppComponent, NavbarComponent],
  imports: [
    CoreModule.forRoot(),
    PagesModule,
    SharedModule,
    ModalModule,
    RouterModule.forRoot(APP_ROUTES, { scrollPositionRestoration: 'top' }),
    BrowserModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    ButtonComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: AppTranslateLoader,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingTranslationHandler,
      },
      defaultLanguage: LANG_DEFAULT,
    }),
    RecaptchaV3Module,
  ],
  exports: [AppComponent],
  providers: [
    { provide: ErrorHandler, useValue: sentry.createErrorHandler() },
    ReCaptchaV3Service,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: env.recaptchaSiteKey },
    // todo: test and opt-in when ready, to unlock future zoneless mode
    // https://blog.angular.dev/angular-v18-is-now-available-e79d5ac0affe
    // provideZoneChangeDetection({
    //   eventCoalescing: true,
    // }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
